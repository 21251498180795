<template>
  <div class="manage-body groups">
    <div class="d-flex justify-content-between align-items-center team-header">
      <div>
        <h3 class="second-title">Groups</h3>
        <p class="second-details">A group consists of members within your organization who collaborate as a team toward a shared objective.</p>
      </div>
    </div>
    <div class="tab-wrapper">
      <div class="card b-card">
        <div class="card-body">
          <div class="t-archived">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="tb-sn">SN</th>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="groups.length > 0">
                  <tr v-for="(item, index) in groups" :key="index">
                    <td class="px-0 td-sd">{{ ++index }}</td>
                    <td class="f-13">{{ item.title }}</td>
                    <td class="f-13">
                      <div class="d-flex flex-wrap">
                        <p class="item-round-x" v-for="(item2, index2) in item.staffs" :key="index2">
                          <span clip>{{ item2.userprofile.first_name }} {{ item2.userprofile.last_name }}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            class="cursor"
                            data-bs-target="#deleteMember"
                            data-bs-toggle="modal"
                            @click="deleteMember(true, item, item2)"
                          >
                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#DC3646" />
                            <path d="M13 7L7 13M7 7L13 13" stroke="#DC3646" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <svg
                          data-bs-toggle="modal"
                          data-bs-target="#editGroupModal"
                          @click="setGroup(item)"
                          class="me-2 cursor"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M12.1011 2.95742L14.0426 4.898M13.3496 1.24784L8.09984 6.49759C7.82858 6.76846 7.64359 7.11357 7.56817 7.48942L7.08325 9.91675L9.51059 9.43092C9.88642 9.35575 10.2311 9.1715 10.5024 8.90017L15.7522 3.65042C15.9099 3.49266 16.0351 3.30538 16.1204 3.09926C16.2058 2.89314 16.2498 2.67223 16.2498 2.44913C16.2498 2.22603 16.2058 2.00511 16.1204 1.79899C16.0351 1.59287 15.9099 1.40559 15.7522 1.24784C15.5944 1.09008 15.4071 0.964941 15.201 0.879564C14.9949 0.794187 14.774 0.750244 14.5509 0.750244C14.3278 0.750244 14.1069 0.794187 13.9007 0.879564C13.6946 0.964941 13.5073 1.09008 13.3496 1.24784Z"
                            stroke="#022438"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.4165 11.7502V14.5002C14.4165 14.9864 14.2233 15.4527 13.8795 15.7965C13.5357 16.1403 13.0694 16.3335 12.5832 16.3335H2.49984C2.01361 16.3335 1.54729 16.1403 1.20347 15.7965C0.859658 15.4527 0.666504 14.9864 0.666504 14.5002V4.41683C0.666504 3.9306 0.859658 3.46428 1.20347 3.12047C1.54729 2.77665 2.01361 2.5835 2.49984 2.5835H5.24984"
                            stroke="#022438"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <svg
                          data-bs-toggle="modal"
                          data-bs-target="#deleteGroup"
                          @click="setGroup(item)"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="cursor-pointer"
                        >
                          <path
                            d="M10.125 4.875V5.25H13.875V4.875C13.875 4.37772 13.6775 3.90081 13.3258 3.54917C12.9742 3.19754 12.4973 3 12 3C11.5027 3 11.0258 3.19754 10.6742 3.54917C10.3225 3.90081 10.125 4.37772 10.125 4.875ZM8.625 5.25V4.875C8.625 3.97989 8.98058 3.12145 9.61351 2.48851C10.2464 1.85558 11.1049 1.5 12 1.5C12.8951 1.5 13.7535 1.85558 14.3865 2.48851C15.0194 3.12145 15.375 3.97989 15.375 4.875V5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6C21.75 6.19891 21.671 6.38968 21.5303 6.53033C21.3897 6.67098 21.1989 6.75 21 6.75H19.869L18.45 19.176C18.3454 20.0911 17.9076 20.9358 17.2201 21.5488C16.5326 22.1618 15.6436 22.5004 14.7225 22.5H9.2775C8.3564 22.5004 7.46735 22.1618 6.77989 21.5488C6.09243 20.9358 5.65464 20.0911 5.55 19.176L4.131 6.75H3C2.80109 6.75 2.61032 6.67098 2.46967 6.53033C2.32902 6.38968 2.25 6.19891 2.25 6C2.25 5.80109 2.32902 5.61032 2.46967 5.46967C2.61032 5.32902 2.80109 5.25 3 5.25H8.625ZM7.041 19.005C7.10362 19.5539 7.36602 20.0606 7.77819 20.4286C8.19037 20.7965 8.7235 20.9999 9.276 21H14.7233C15.2757 20.9999 15.8089 20.7965 16.2211 20.4286C16.6332 20.0606 16.8956 19.5539 16.9583 19.005L18.36 6.75H5.64075L7.041 19.005ZM9.75 9.375C9.94891 9.375 10.1397 9.45402 10.2803 9.59467C10.421 9.73532 10.5 9.92609 10.5 10.125V17.625C10.5 17.8239 10.421 18.0147 10.2803 18.1553C10.1397 18.296 9.94891 18.375 9.75 18.375C9.55109 18.375 9.36032 18.296 9.21967 18.1553C9.07902 18.0147 9 17.8239 9 17.625V10.125C9 9.92609 9.07902 9.73532 9.21967 9.59467C9.36032 9.45402 9.55109 9.375 9.75 9.375ZM15 10.125C15 9.92609 14.921 9.73532 14.7803 9.59467C14.6397 9.45402 14.4489 9.375 14.25 9.375C14.0511 9.375 13.8603 9.45402 13.7197 9.59467C13.579 9.73532 13.5 9.92609 13.5 10.125V17.625C13.5 17.8239 13.579 18.0147 13.7197 18.1553C13.8603 18.296 14.0511 18.375 14.25 18.375C14.4489 18.375 14.6397 18.296 14.7803 18.1553C14.921 18.0147 15 17.8239 15 17.625V10.125Z"
                            fill="#FF5555"
                          ></path>
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <NoData v-else :data="{ colspan: 4, message: 'No groups' }"></NoData>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal-custom" id="addGroup" tabindex="-1" aria-labelledby="addGroupLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16.5" r="15.5" fill="#FAFAFF" stroke="#DFDFDF" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6253 12C12.6253 11.1049 12.9809 10.2464 13.6138 9.61351C14.2468 8.98058 15.1052 8.625 16.0003 8.625C16.8954 8.625 17.7539 8.98058 18.3868 9.61351C19.0197 10.2464 19.3753 11.1049 19.3753 12C19.3753 12.8951 19.0197 13.7536 18.3868 14.3865C17.7539 15.0194 16.8954 15.375 16.0003 15.375C15.1052 15.375 14.2468 15.0194 13.6138 14.3865C12.9809 13.7536 12.6253 12.8951 12.6253 12ZM9.81357 22.5787C9.83886 20.9546 10.5018 19.4055 11.6593 18.2659C12.8168 17.1263 14.376 16.4876 16.0003 16.4876C17.6247 16.4876 19.1839 17.1263 20.3413 18.2659C21.4988 19.4055 22.1618 20.9546 22.1871 22.5787C22.189 22.6881 22.1591 22.7956 22.1008 22.8882C22.0426 22.9808 21.9587 23.0544 21.8593 23.1C20.0212 23.9428 18.0224 24.3777 16.0003 24.375C13.9108 24.375 11.9256 23.919 10.1413 23.1C10.0419 23.0544 9.958 22.9808 9.89979 22.8882C9.84158 22.7956 9.81162 22.6881 9.81357 22.5787Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Add New Group</h5>
            </div>
            <p class="f-13 text-muted mt-3">Fill the details to create a new group</p>
            <form @submit.prevent="addGroup()" class="form">
              <div class="form-group">
                <label for="fname required" class="mb-1 f-14">Group Name <span class="required">*</span> </label>
                <input type="text" id="fname" class="form-control f-13" v-model="group.title" />
              </div>
              <div class="form-group">
                <label for="fname required" class="mb-1 f-14">Description </label>
                <textarea class="form-control" id="" cols="30" rows="2" v-model="group.description"></textarea>
              </div>
              <div class="form-group">
                <label for="lname" class="mb-1 f-14">Group Members</label>
                <multiselect
                  v-model="member"
                  @select="addMember(false)"
                  :options="staffsData"
                  placeholder="Select member"
                  :show-labels="false"
                  :custom-label="firstLastName"
                  label="first_name"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <!-- <span class="option__title">{{ props.option.user.first_name }} {{ props.option.user.last_name }}</span> -->
                      <span class="option__title" v-if="props.option.first_name">{{ props.option.first_name }} {{ props.option.last_name }}</span>
                      <span v-else>
                        {{ props.option.email }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </div>
              <div class="mem-list" v-if="members.length > 0">
                <p class="d-flex justify-content-between mb-2" v-for="(item, index) in members" :key="index">
                  <span class="f-15">{{ item.first_name }} {{ item.last_name }}</span>
                  <svg
                    data-v-79c7605c=""
                    @click="removeMember(index)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="cursor-pointer"
                  >
                    <path
                      data-v-79c7605c=""
                      d="M10.125 4.875V5.25H13.875V4.875C13.875 4.37772 13.6775 3.90081 13.3258 3.54917C12.9742 3.19754 12.4973 3 12 3C11.5027 3 11.0258 3.19754 10.6742 3.54917C10.3225 3.90081 10.125 4.37772 10.125 4.875ZM8.625 5.25V4.875C8.625 3.97989 8.98058 3.12145 9.61351 2.48851C10.2464 1.85558 11.1049 1.5 12 1.5C12.8951 1.5 13.7535 1.85558 14.3865 2.48851C15.0194 3.12145 15.375 3.97989 15.375 4.875V5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6C21.75 6.19891 21.671 6.38968 21.5303 6.53033C21.3897 6.67098 21.1989 6.75 21 6.75H19.869L18.45 19.176C18.3454 20.0911 17.9076 20.9358 17.2201 21.5488C16.5326 22.1618 15.6436 22.5004 14.7225 22.5H9.2775C8.3564 22.5004 7.46735 22.1618 6.77989 21.5488C6.09243 20.9358 5.65464 20.0911 5.55 19.176L4.131 6.75H3C2.80109 6.75 2.61032 6.67098 2.46967 6.53033C2.32902 6.38968 2.25 6.19891 2.25 6C2.25 5.80109 2.32902 5.61032 2.46967 5.46967C2.61032 5.32902 2.80109 5.25 3 5.25H8.625ZM7.041 19.005C7.10362 19.5539 7.36602 20.0606 7.77819 20.4286C8.19037 20.7965 8.7235 20.9999 9.276 21H14.7233C15.2757 20.9999 15.8089 20.7965 16.2211 20.4286C16.6332 20.0606 16.8956 19.5539 16.9583 19.005L18.36 6.75H5.64075L7.041 19.005ZM9.75 9.375C9.94891 9.375 10.1397 9.45402 10.2803 9.59467C10.421 9.73532 10.5 9.92609 10.5 10.125V17.625C10.5 17.8239 10.421 18.0147 10.2803 18.1553C10.1397 18.296 9.94891 18.375 9.75 18.375C9.55109 18.375 9.36032 18.296 9.21967 18.1553C9.07902 18.0147 9 17.8239 9 17.625V10.125C9 9.92609 9.07902 9.73532 9.21967 9.59467C9.36032 9.45402 9.55109 9.375 9.75 9.375ZM15 10.125C15 9.92609 14.921 9.73532 14.7803 9.59467C14.6397 9.45402 14.4489 9.375 14.25 9.375C14.0511 9.375 13.8603 9.45402 13.7197 9.59467C13.579 9.73532 13.5 9.92609 13.5 10.125V17.625C13.5 17.8239 13.579 18.0147 13.7197 18.1553C13.8603 18.296 14.0511 18.375 14.25 18.375C14.4489 18.375 14.6397 18.296 14.7803 18.1553C14.921 18.0147 15 17.8239 15 17.625V10.125Z"
                      fill="#FF5555"
                    ></path>
                  </svg>
                </p>
              </div>
              <div class="submit mt-3 d-flex justify-content-end">
                <button class="btn btn-primary f-13" type="submit">Create Group</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-custom" data-bs-backdrop="static" id="editGroupModal" tabindex="-1" aria-labelledby="editGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header">
              <div class="d-flex align-items-center">
                <div class="add-i">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <circle cx="16" cy="16" r="15.5" stroke="#022438" />
                    <path
                      d="M13.1463 18.9702L14.3203 18.6316L21.389 11.4782C21.4448 11.4211 21.4758 11.3443 21.4754 11.2645C21.4751 11.1847 21.4433 11.1082 21.387 11.0516L20.9637 10.6236C20.9363 10.5955 20.9036 10.5731 20.8675 10.5577C20.8314 10.5424 20.7926 10.5344 20.7534 10.5342C20.7142 10.534 20.6753 10.5417 20.6391 10.5567C20.6029 10.5717 20.57 10.5937 20.5423 10.6216L13.4923 17.7562L13.1457 18.9696L13.1463 18.9702ZM21.8017 9.77557L22.225 10.2042C22.809 10.7956 22.8143 11.7496 22.2357 12.3349L14.9517 19.7069L12.4423 20.4296C12.2891 20.4725 12.1252 20.4528 11.9864 20.3749C11.8477 20.2971 11.7455 20.1674 11.7023 20.0142C11.6702 19.9042 11.6697 19.7872 11.701 19.6769L12.431 17.1169L19.6957 9.76424C19.8338 9.62514 19.9983 9.51495 20.1794 9.44007C20.3606 9.3652 20.5549 9.32715 20.7509 9.32814C20.9469 9.32914 21.1408 9.36915 21.3212 9.44586C21.5016 9.52256 21.6649 9.63509 21.8017 9.77557ZM14.1223 10.5442C14.453 10.5442 14.721 10.8156 14.721 11.1502C14.7215 11.2294 14.7065 11.3078 14.6767 11.3811C14.6468 11.4544 14.6029 11.521 14.5473 11.5773C14.4917 11.6336 14.4255 11.6784 14.3526 11.7091C14.2797 11.7398 14.2015 11.7558 14.1223 11.7562H11.7277C11.0663 11.7562 10.5303 12.2989 10.5303 12.9676V20.2382C10.5303 20.9076 11.0663 21.4502 11.7277 21.4502H18.9117C19.573 21.4502 20.1097 20.9076 20.1097 20.2382V17.8149C20.1097 17.4802 20.3777 17.2089 20.7083 17.2089C21.039 17.2089 21.307 17.4802 21.307 17.8156V20.2382C21.307 21.5769 20.2343 22.6622 18.9117 22.6622H11.7277C10.405 22.6622 9.33301 21.5769 9.33301 20.2382V12.9676C9.33301 11.6296 10.405 10.5442 11.7277 10.5442H14.1223Z"
                      fill="#022438"
                    />
                  </svg>
                </div>
                <h4 class="mb-0 ms-2">Edit Group Details</h4>
              </div>
            </div>
            <div class="form mt-4" v-if="editGroup">
              <form @submit.prevent="updateGroup()" class="form">
                <div class="form-group">
                  <label for="fname required" class="mb-1 f-14">Group Name <span class="required">*</span> </label>
                  <input type="text" id="fname" class="form-control f-13" v-model="editGroup.title" />
                </div>
                <div class="form-group">
                  <label for="fname required" class="mb-1 f-14">Description </label>
                  <textarea class="form-control" id="" cols="30" rows="2" v-model="editGroup.description"></textarea>
                </div>
                <div class="form-group multi-s-40">
                  <label for="lname" class="mb-1 f-14">Group Members</label>
                  <multiselect
                    v-model="member"
                    @select="addMember(true)"
                    :options="staffsData2"
                    placeholder="Select member"
                    :show-labels="false"
                    :custom-label="firstLastName"
                    label="first_name"
                  >
                    <template slot="option" slot-scope="props">
                      <span class="option__title" v-if="props.option.first_name">{{ props.option.first_name }} {{ props.option.last_name }}</span>
                      <span v-else>
                        {{ props.option.email }}
                      </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mem-list" v-if="editGroup.staffs.length > 0">
                  <p class="d-flex justify-content-between mb-2" v-for="(item, index) in editGroup.staffs" :key="index">
                    <span class="f-15" v-if="item.userprofile.first_name">{{ item.userprofile.first_name }} {{ item.userprofile.last_name }}</span>
                    <span class="f-15" v-else>{{ item.userprofile.email }}</span>
                    <svg
                      data-v-79c7605c=""
                      @click="removeMember(index, true)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      class="cursor-pointer"
                    >
                      <path
                        data-v-79c7605c=""
                        d="M10.125 4.875V5.25H13.875V4.875C13.875 4.37772 13.6775 3.90081 13.3258 3.54917C12.9742 3.19754 12.4973 3 12 3C11.5027 3 11.0258 3.19754 10.6742 3.54917C10.3225 3.90081 10.125 4.37772 10.125 4.875ZM8.625 5.25V4.875C8.625 3.97989 8.98058 3.12145 9.61351 2.48851C10.2464 1.85558 11.1049 1.5 12 1.5C12.8951 1.5 13.7535 1.85558 14.3865 2.48851C15.0194 3.12145 15.375 3.97989 15.375 4.875V5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6C21.75 6.19891 21.671 6.38968 21.5303 6.53033C21.3897 6.67098 21.1989 6.75 21 6.75H19.869L18.45 19.176C18.3454 20.0911 17.9076 20.9358 17.2201 21.5488C16.5326 22.1618 15.6436 22.5004 14.7225 22.5H9.2775C8.3564 22.5004 7.46735 22.1618 6.77989 21.5488C6.09243 20.9358 5.65464 20.0911 5.55 19.176L4.131 6.75H3C2.80109 6.75 2.61032 6.67098 2.46967 6.53033C2.32902 6.38968 2.25 6.19891 2.25 6C2.25 5.80109 2.32902 5.61032 2.46967 5.46967C2.61032 5.32902 2.80109 5.25 3 5.25H8.625ZM7.041 19.005C7.10362 19.5539 7.36602 20.0606 7.77819 20.4286C8.19037 20.7965 8.7235 20.9999 9.276 21H14.7233C15.2757 20.9999 15.8089 20.7965 16.2211 20.4286C16.6332 20.0606 16.8956 19.5539 16.9583 19.005L18.36 6.75H5.64075L7.041 19.005ZM9.75 9.375C9.94891 9.375 10.1397 9.45402 10.2803 9.59467C10.421 9.73532 10.5 9.92609 10.5 10.125V17.625C10.5 17.8239 10.421 18.0147 10.2803 18.1553C10.1397 18.296 9.94891 18.375 9.75 18.375C9.55109 18.375 9.36032 18.296 9.21967 18.1553C9.07902 18.0147 9 17.8239 9 17.625V10.125C9 9.92609 9.07902 9.73532 9.21967 9.59467C9.36032 9.45402 9.55109 9.375 9.75 9.375ZM15 10.125C15 9.92609 14.921 9.73532 14.7803 9.59467C14.6397 9.45402 14.4489 9.375 14.25 9.375C14.0511 9.375 13.8603 9.45402 13.7197 9.59467C13.579 9.73532 13.5 9.92609 13.5 10.125V17.625C13.5 17.8239 13.579 18.0147 13.7197 18.1553C13.8603 18.296 14.0511 18.375 14.25 18.375C14.4489 18.375 14.6397 18.296 14.7803 18.1553C14.921 18.0147 15 17.8239 15 17.625V10.125Z"
                        fill="#FF5555"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div class="submit mt-3 d-flex justify-content-end">
                  <button class="btn btn-primary f-13" type="submit">Update Group</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-custom" id="deleteGroup" tabindex="-1" aria-labelledby="deleteGroupLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <path
                  d="M20.5002 3.33334C29.7052 3.33334 37.1668 10.795 37.1668 20C37.1668 29.205 29.7052 36.6667 20.5002 36.6667C11.2952 36.6667 3.8335 29.205 3.8335 20C3.8335 10.795 11.2952 3.33334 20.5002 3.33334ZM20.5002 26.6667C20.0581 26.6667 19.6342 26.8423 19.3217 27.1548C19.0091 27.4674 18.8335 27.8913 18.8335 28.3333C18.8335 28.7754 19.0091 29.1993 19.3217 29.5119C19.6342 29.8244 20.0581 30 20.5002 30C20.9422 30 21.3661 29.8244 21.6787 29.5119C21.9912 29.1993 22.1668 28.7754 22.1668 28.3333C22.1668 27.8913 21.9912 27.4674 21.6787 27.1548C21.3661 26.8423 20.9422 26.6667 20.5002 26.6667ZM20.5002 10.8333C18.8978 10.8333 17.3611 11.4699 16.2281 12.6029C15.095 13.7359 14.4585 15.2727 14.4585 16.875C14.4585 17.317 14.6341 17.741 14.9467 18.0535C15.2592 18.3661 15.6831 18.5417 16.1252 18.5417C16.5672 18.5417 16.9911 18.3661 17.3037 18.0535C17.6162 17.741 17.7918 17.317 17.7918 16.875C17.7924 16.3834 17.9267 15.9012 18.1805 15.4802C18.4342 15.0591 18.7978 14.715 19.2322 14.4849C19.6666 14.2547 20.1554 14.1471 20.6463 14.1737C21.1372 14.2002 21.6116 14.3598 22.0187 14.6355C22.4257 14.9111 22.7501 15.2923 22.957 15.7383C23.1639 16.1842 23.2455 16.6781 23.1931 17.1669C23.1406 17.6557 22.9561 18.121 22.6594 18.513C22.3626 18.9049 21.9648 19.2087 21.5085 19.3917C20.3818 19.8417 18.8335 20.995 18.8335 22.9167V23.3333C18.8335 23.7754 19.0091 24.1993 19.3217 24.5119C19.6342 24.8244 20.0581 25 20.5002 25C20.9422 25 21.3661 24.8244 21.6787 24.5119C21.9912 24.1993 22.1668 23.7754 22.1668 23.3333C22.1668 22.9267 22.2502 22.7233 22.6018 22.55L22.7468 22.4833C24.0482 21.9598 25.1269 20.9999 25.7981 19.7682C26.4692 18.5365 26.691 17.1097 26.4254 15.7323C26.1598 14.355 25.4233 13.1129 24.3422 12.2191C23.2612 11.3253 21.9029 10.8354 20.5002 10.8333Z"
                  fill="#dc3646"
                />
              </svg>
              <h4 class="mb-0 ms-2">Delete Group</h4>
            </div>
            <div class="d-flex flex-column pop-up">
              <p class="text-primary mb-4 mt-3">Are you sure you want to continue?</p>
              <button class="btn btn-primary w-fit" @click="deleteGroup()">Delete Group</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-custom" id="deleteMember" tabindex="-1" aria-labelledby="deleteMemberLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <path
                  d="M20.5002 3.33334C29.7052 3.33334 37.1668 10.795 37.1668 20C37.1668 29.205 29.7052 36.6667 20.5002 36.6667C11.2952 36.6667 3.8335 29.205 3.8335 20C3.8335 10.795 11.2952 3.33334 20.5002 3.33334ZM20.5002 26.6667C20.0581 26.6667 19.6342 26.8423 19.3217 27.1548C19.0091 27.4674 18.8335 27.8913 18.8335 28.3333C18.8335 28.7754 19.0091 29.1993 19.3217 29.5119C19.6342 29.8244 20.0581 30 20.5002 30C20.9422 30 21.3661 29.8244 21.6787 29.5119C21.9912 29.1993 22.1668 28.7754 22.1668 28.3333C22.1668 27.8913 21.9912 27.4674 21.6787 27.1548C21.3661 26.8423 20.9422 26.6667 20.5002 26.6667ZM20.5002 10.8333C18.8978 10.8333 17.3611 11.4699 16.2281 12.6029C15.095 13.7359 14.4585 15.2727 14.4585 16.875C14.4585 17.317 14.6341 17.741 14.9467 18.0535C15.2592 18.3661 15.6831 18.5417 16.1252 18.5417C16.5672 18.5417 16.9911 18.3661 17.3037 18.0535C17.6162 17.741 17.7918 17.317 17.7918 16.875C17.7924 16.3834 17.9267 15.9012 18.1805 15.4802C18.4342 15.0591 18.7978 14.715 19.2322 14.4849C19.6666 14.2547 20.1554 14.1471 20.6463 14.1737C21.1372 14.2002 21.6116 14.3598 22.0187 14.6355C22.4257 14.9111 22.7501 15.2923 22.957 15.7383C23.1639 16.1842 23.2455 16.6781 23.1931 17.1669C23.1406 17.6557 22.9561 18.121 22.6594 18.513C22.3626 18.9049 21.9648 19.2087 21.5085 19.3917C20.3818 19.8417 18.8335 20.995 18.8335 22.9167V23.3333C18.8335 23.7754 19.0091 24.1993 19.3217 24.5119C19.6342 24.8244 20.0581 25 20.5002 25C20.9422 25 21.3661 24.8244 21.6787 24.5119C21.9912 24.1993 22.1668 23.7754 22.1668 23.3333C22.1668 22.9267 22.2502 22.7233 22.6018 22.55L22.7468 22.4833C24.0482 21.9598 25.1269 20.9999 25.7981 19.7682C26.4692 18.5365 26.691 17.1097 26.4254 15.7323C26.1598 14.355 25.4233 13.1129 24.3422 12.2191C23.2612 11.3253 21.9029 10.8354 20.5002 10.8333Z"
                  fill="#dc3646"
                />
              </svg>
              <h4 class="mb-0 ms-2">Delete Member</h4>
            </div>
            <div class="d-flex flex-column pop-up">
              <p class="text-primary mb-4 mt-3">Are you sure you want to continue?</p>
              <button class="btn btn-primary w-fit" @click="deleteMember()">Delete Member</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import NoData from '@/components/dashboard/noData.vue';
export default {
  props: { staffs: [], groups: [] },
  components: {
    Multiselect,
    NoData,
  },
  data() {
    return {
      group: {
        title: '',
        description: null,
        staffs: [],
      },
      editGroup: null,
      member: null,
      members: [],
      // groups: [],
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
      staffsData: [],
      staffsData2: [],
      deleteUserId: null,
    };
  },
  methods: {
    addMember(edit = false) {
      console.log(this.member);
      if (edit) {
        console.log(this.editGroup);
        this.editGroup.staffs.push({
          ...this.member,
          userprofile: { email: this.member.email, first_name: this.member.first_name, last_name: this.member.last_name, user_id: this.member.id },
        });
        this.staffsData2.splice(
          this.staffsData2.findIndex((item) => item.id == this.member.id),
          1
        );
      } else {
        if (!this.members.find((item) => item.id == this.member.id)) {
          this.members.push({ ...this.member });
        }
        this.staffsData.splice(
          this.staffsData.findIndex((item) => item.id == this.member.id),
          1
        );
      }
      this.member = null;
    },
    setGroup(member) {
      this.members = [];
      this.editGroup = member;
      this.staffsData2 = [...this.staffs];
      this.editGroup.staffs.forEach((staff) => {
        this.staffsData2.splice(
          this.staffsData2.findIndex((item) => item.id == staff.userprofile.user_id),
          1
        );
      });
    },
    firstLastName({ first_name, last_name, email }) {
      return first_name ? `${first_name} ${last_name}` : email;
    },
    removeMember(index, edit = false) {
      if (edit) {
        var hd = this.editGroup.staffs[index];
        hd['id'] = hd.userprofile.user_id;
        hd['first_name'] = hd.userprofile.first_name;
        hd['last_name'] = hd.userprofile.last_name;
        hd['email'] = hd.userprofile.email;
        this.editGroup.staffs.splice(index, 1);
        this.staffsData2.push(hd);
      } else {
        var a = this.members.splice(index, 1);
        this.staffsData.push(a[0]);
      }
    },
    deleteMember(set = false, item, item2) {
      if (set) {
        this.editGroup = item;
        this.deleteUserId = item2.userprofile.id;
      } else {
        this.editGroup.staffs.splice(
          this.editGroup.staffs.findIndex((item) => item.userprofile.id == this.deleteUserId),
          1
        );
        this.updateGroup();
      }
    },
    deleteGroup() {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('delete', { endpoint: `company/groups/${this.editGroup.id}/${this.$store.state.user.id}` }).then((resp) => {
        console.log(resp);
        this.$store.commit('setLoader', false);
        window.ToasterAlert('success', 'Group deleted');
        window.setTimeout(function () {
          window.location.reload();
        }, 1000);
      });
    },
    updateGroup() {
      if (this.editGroup.title.length < 1) {
        window.ToasterAlert('error', 'Title is required');
      }
      console.log({ ...this.editGroup });
      this.editGroup.staffs = this.editGroup.staffs.map((b) => {
        return { id: b.userprofile.user_id };
      });
      this.$store.commit('setLoader', true);
      this.$store.dispatch('put', { endpoint: `company/groups/${this.editGroup.id}/${this.$store.state.user.id}`, details: this.editGroup }).then(() => {
        this.$store.commit('setLoader', false);
        window.ToasterAlert('success', 'Group updated');
        window.setTimeout(function () {
          window.location.reload();
        }, 1500);
      });
    },

    addGroup() {
      if (this.group.title.length < 1) {
        window.ToasterAlert('error', 'Title is required');
      }
      this.group.staffs = this.members.map((b) => {
        return { id: b.id };
      });
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', { endpoint: `company/groups/${this.$store.state.user.id}`, details: this.group })
        .then(() => {
          window.ToasterAlert('success', 'Group added');
          window.setTimeout(function () {
            window.location.reload();
          }, 1500);
        })
        .catch(() => {
          this.$store.commit('setLoader', false);
        });
    },
  },
  created() {
    this.staffsData = [...this.staffs];
  },
};
</script>

<style>
.multi-s-40 .multiselect__tags {
  min-height: 36px !important;
  border: 1px solid #ced4da;
}
.multi-s-40 .multiselect__tags .multiselect__placeholder {
  margin-bottom: 0 !important;
}
.multi-s-40 .multiselect__input,
.multi-s-40 .multiselect__single {
  color: #012538 !important;
  min-height: 0 !important;
  font-size: 14px !important;
}
</style>
<style scoped lang="scss">
.mem-list {
  border: 1px solid #d2d1d1;
  border-radius: 8px;
  max-height: 10em;
  padding: 10px;
}
.second-title {
  color: #022438;
  font-size: 15px;
  font-weight: bold;
}
.second-details {
  color: #6a6a6a;
  margin-bottom: 0;
  font-size: 13px;
}
.team-header {
  border: 1px solid rgb(236, 236, 236);
  border-top: 0;
  background: #fff;
  padding: 9px 18px;
}
.tab-wrapper {
  padding: 0;
  border-radius: 0 0 8px 8px;
  border-top: 0;
  .card.b-card {
    border-radius: 0 0 8px 8px;
    border: 0;
    width: 100%;
  }
}
</style>
