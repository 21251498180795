// import Home from '../views/public/home.vue';
import Login from '../views/public/login.vue';
import Register from '../views/public/register.vue';
import Step1 from '../views/public/verify/step1.vue';
import Pending from '../views/public/pending.vue';
import Waitlist from '../views/public/waitlist.vue';
import ForgetPassword from '../views/public/forget-password.vue';
import ResetPassword from '../views/public/reset-password.vue';
import ConfirmEmail from '../views/public/confirm-email.vue';

const publicRoutes = [
  {
    path: '/ambassador',
    beforeEnter() {
      window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdi2OOxfFx6DaNBl250o1mqYr_LJr0hLJg3VAFh9GQssxuVVw/viewform';
    },
  },

  {
    path: '/affiliate',
    beforeEnter() {
      window.location.href = 'https://forms.gle/F1BoQwr6MNmT6HgX8';
    },
  },

  {
    path: '/bsmpemail',
    beforeEnter() {
      window.location.href = 'https://buysimply.app:2096/';
    },
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/';
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/security',
    name: 'security',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/security/';
    },
  },
  {
    path: '/faq',
    name: 'faq',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/faqs';
    },
  },
  {
    path: '/contact',
    name: 'contact',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/contact-us';
    },
  },
  {
    path: '/buysimply-login',
    name: 'login',
    component: Login,
  },
  {
    path: '/about',
    name: 'about',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/about-us/';
    },
    // component: About,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/privacy',
    name: 'privacy',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/privacy-policy/';
    },
  },
  {
    path: '/terms-condition',
    name: 'terms',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/terms-of-use/';
    },
  },
  {
    path: '/',
    name: 'home',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/';
    },
  },
  {
    path: '/verify/get-started/:token',
    name: 'step1',
    component: Step1,
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/confirm-mail',
    name: 'confirmEmail',
    component: ConfirmEmail,
  },
  {
    path: '/pending',
    name: 'pending',
    component: Pending,
  },
  {
    path: '/archived-pending',
    name: 'archivedPending',
    component: () => import('@/views/public/archived.vue'),
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: Waitlist,
  },
  {
    path: '/supplier/register',
    name: 'registerRegister',
    component: () => import('@/views/public/supplier/register.vue'),
  },
  {
    path: '/request-demo',
    name: 'requestDemo',
    beforeEnter() {
      window.location.href = 'https://zcal.co/buysimply/ChatwithBuySimply';
    },
  },
  {
    path: 'pricing',
    name: 'pricing',
    beforeEnter() {
      window.location.href = 'https://web.buysimply.app/pricing/';
    },
  },
];
export default publicRoutes;
