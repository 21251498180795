<template>
  <div class="manage" v-if="loaded">
    <div class="tp-setting mb-2">
      <div class="appr-req">
        <h6>Team</h6>
      </div>

      <div v-if="showGroup">
        <div class="d-flex manage-top-btns gap-3" v-if="checked.length > 0">
          <button v-if="paramMode == 5 || paramMode == 1" class="btn btn-primary f-12" data-bs-toggle="modal" data-bs-target="#archiveMembers">Archive Members</button>
          <button v-if="paramMode == 3" class="btn btn-primary f-12" data-bs-toggle="modal" data-bs-target="#unarchiveMembers">Unarchive Members</button>
          <div class="d-flex" v-if="paramMode == 4">
            <button class="btn btn-outline-success f-12 me-2 mt-1">Accept</button>
            <button class="btn btn-outline-danger f-12 mt-1">Reject</button>
          </div>
        </div>
        <div class="d-flex manage-top-btns gap-3" v-else>
          <button class="btn btn-outline f-14" @click="exportToExcel()" :disabled="usersExportLoading">
            <span v-if="!usersExportLoading">Export</span>
            <span v-else>Loading...</span>
          </button>
          <button class="btn btn-darkBlue f-14" data-bs-toggle="modal" data-bs-target="#manageAccess">Add Team Member</button>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-darkBlue f-14" data-bs-toggle="modal" data-bs-target="#addGroup">Add Group</button>
      </div>
    </div>
    <div class="set-box p-0">
      <ul class="nav nav-pills mb-0 flex-wrap w-100" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#pills-teams" @click="showGroup = true">Team Permissions</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" data-bs-toggle="pill" data-bs-target="#pills-groups" @click="showGroup = false">Groups</button>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-teams" role="tabpanel" aria-labelledby="pills-teams-tab">
        <div class="manage-body">
          <div class="d-flex justify-content-between align-items-center team-header">
            <div>
              <p class="second-details">Modify what an individual role(s) can be</p>
            </div>
            <div class="input-group main-search">
              <input type="text" class="form-control" v-model="search" />
              <span @click="getUsers()" class="input-group-text bg-primary border-0 cursor-pointer" id="basic-addon2">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.34088 2.625C6.30928 2.625 5.30084 2.93091 4.44309 3.50403C3.58535 4.07716 2.91681 4.89177 2.52204 5.84485C2.12726 6.79793 2.02397 7.84666 2.22522 8.85845C2.42648 9.87023 2.92324 10.7996 3.6527 11.5291C4.38215 12.2585 5.31153 12.7553 6.32331 12.9565C7.33509 13.1578 8.38383 13.0545 9.33691 12.6597C10.29 12.2649 11.1046 11.5964 11.6777 10.7387C12.2509 9.88092 12.5568 8.87248 12.5568 7.84088C12.5567 6.45757 12.0071 5.13094 11.029 4.15279C10.0508 3.17464 8.72419 2.62509 7.34088 2.625Z"
                    stroke="white"
                    stroke-miterlimit="10"
                  />
                  <path d="M11.2323 11.7324L14.875 15.3751" stroke="white" stroke-miterlimit="10" stroke-linecap="round" />
                </svg>
              </span>
            </div>
          </div>
          <div class="tab-wrapper">
            <ul class="nav nav-pills mb-0 flex-wrap w-100" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link" :class="paramMode == 5 ? 'active' : ''" id="online-tab" href="/team?mode=5"> Internal Members </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" :class="paramMode == 1 ? 'active' : ''" id="online-tab" href="/team?mode=1"> External Members </a>
              </li>

              <li class="nav-item" role="presentation">
                <a href="/team?mode=2" class="nav-link" id="purchase-tab" :class="paramMode == 2 ? 'active' : ''"> Pending Registration </a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="/team?mode=3" class="nav-link" id="purchase-tab" :class="paramMode == 3 ? 'active' : ''"> Archived </a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="/team?mode=4" class="nav-link" id="purchase-tab" :class="paramMode == 4 ? 'active' : ''"> Join Requests </a>
              </li>
            </ul>

            <div class="card b-card">
              <div class="card-body">
                <div class="t-all" v-if="paramMode == 5 || paramMode == 1">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="">
                            <input class="form-check-input bg-checked" :checked="checkedAllStatus" @change="checkAll()" type="checkbox" name="profile" />
                          </th>
                          <th class="px-0"></th>
                          <th class="td-center" v-if="paramMode != 1">
                            Requester
                            <!-- <i
                          v-popover:requester
                          class="fa fa-info-circle cursor-pointer p-color"
                        ></i> -->
                            <svg v-popover:requester width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <popover name="requester">
                              Everyone within your business that can make a request for products and services. The end-users of internal business operations purchases.
                            </popover>
                          </th>
                          <th class="td-center" v-if="paramMode != 1">
                            Endorser
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:endorser
                        ></i> -->
                            <svg v-popover:endorser width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="endorser"> Can view, edit, decline or endorse purchase requests. Is not a final approver. </popover>
                          </th>
                          <th class="td-center" v-if="paramMode != 1">
                            Approver
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:approver
                        ></i> -->
                            <svg v-popover:approver width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="approver"> Team member with permission to view, edit, decline or approve purchase requests from within your business. </popover>
                          </th>
                          <th class="td-center" v-if="paramMode != 1">
                            Tech
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:tech
                        ></i> -->
                            <svg v-popover:tech width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="tech"> Team member can create and manage business profile, team member, groups and integrations with third-party services. </popover>
                          </th>
                          <th class="td-center" v-if="paramMode != 1">
                            Finance
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:finance
                        ></i> -->
                            <svg v-popover:finance width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="finance">
                              Finance profile owners can view purchases and transactions history, add funds to the company wallet and generate expense reports.
                            </popover>
                          </th>
                          <!-- <th class="td-center">
                        Finance Analyst
                        <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:analyst
                        ></i>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                            fill="white"
                            stroke="#022438"
                            stroke-width="1.5"
                          />
                          <path d="M9 5.25H9.009H9Z" fill="#022438" />
                          <path
                            d="M9 5.25H9.009"
                            stroke="#022438"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z"
                            fill="#022438"
                          />
                          <path
                            d="M7.5 8.25H9V12M7.5 12H10.5"
                            stroke="#022438"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <popover name="analyst">
                          Finance analyst can create payee and request payment.
                        </popover>
                      </th> -->
                          <th class="td-center" v-if="paramMode == 1">
                            Supplier
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:supplier
                        ></i> -->
                            <svg v-popover:supplier width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="supplier"> Supplier can create request payments. </popover>
                          </th>

                          <th class="td-center" v-if="paramMode != 1">
                            Admin
                            <!-- <i
                          class="fa fa-info-circle cursor-pointer p-color"
                          v-popover:admin
                        ></i> -->
                            <svg v-popover:admin width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                fill="white"
                                stroke="#022438"
                                stroke-width="1.5"
                              />
                              <path d="M9 5.25H9.009H9Z" fill="#022438" />
                              <path d="M9 5.25H9.009" stroke="#022438" stroke-width="1.5" stroke-linecap="round" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5H7.5Z" fill="#022438" />
                              <path d="M7.5 8.25H9V12M7.5 12H10.5" stroke="#022438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <popover name="admin"> Unrestricted access to all aspects of the dashboard including creating and managing your business account. </popover>
                          </th>
                          <!-- <th class="td-center">Action</th> -->
                        </tr>
                      </thead>
                      <tbody v-if="users.users_result.length > 0">
                        <tr v-for="(user, index) in users.users_result" :key="index">
                          <td class="p-0">
                            <input class="form-check-input mk-check bg-checked" type="checkbox" :checked="checkedAllStatus" @change="addChecked(user.user_id)" name="profile" />
                          </td>
                          <td class="px-0 td-sd">
                            <router-link :to="`team/${user.id}`" class="user-info">
                              <div class="user-details">
                                <h6>{{ user.first_name }} {{ user.last_name }}</h6>
                                <span class="text-muted d-flex"
                                  >{{ user.email }}
                                  <div class="ms-2" v-if="user.pending">
                                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover="{ name: 'pending' + index }"></i>
                                    <popover :name="'pending' + index"> Access level of user awaiting registration cannot be changed </popover>
                                  </div>
                                </span>
                              </div>
                            </router-link>
                          </td>

                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              :checked="havePermission(user, 4)"
                              class="bg-checked"
                              @change="updateRole(user, 4)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 54)"
                              @change="updateRole(user, 54)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 14)"
                              @change="updateRole(user, 14)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 34)"
                              @change="updateRole(user, 34)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 24)"
                              @change="updateRole(user, 24)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <!-- <td class="td-center">
                      <input
                        type="checkbox"
                        :checked="havePermission(user, 74)"
                        @change="updateRole(user, 74)"
                        :disabled="user.pending ? disabledCheck : !disabledCheck"
                      />
                    </td> -->
                          <td class="td-center" v-if="paramMode == 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 84)"
                              @change="updateRole(user, 84)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                          <td class="td-center" v-if="paramMode != 1">
                            <input
                              type="checkbox"
                              class="bg-checked"
                              :checked="havePermission(user, 44)"
                              @change="updateRole(user, 44)"
                              :disabled="user.pending ? disabledCheck : !disabledCheck"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <NoData :data="{ colspan: 7, message: 'No member found' }" v-else></NoData>
                    </table>
                  </div>
                </div>
                <div class="t-archived" v-if="paramMode == 3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="">
                            <input class="form-check-input bg-checked" type="checkbox" name="profile" :checked="checkedAllStatus" @change="checkAll()" />
                          </th>
                          <th class="px-0">Member Details</th>
                          <th class="td-center">Archived By</th>
                          <th class="td-center">Archived Date</th>
                          <th class="td-center"></th>
                        </tr>
                      </thead>
                      <tbody v-if="users.users_result.length > 0">
                        <tr v-for="(user, index) in users.users_result" :key="index">
                          <td class="p-0">
                            <input class="form-check-input mk-check bg-checked" type="checkbox" name="profile" :checked="checkedAllStatus" @change="addChecked(user.user_id)" />
                          </td>
                          <td class="px-0 td-sd">
                            <div class="user-info">
                              <div class="user-details">
                                <h6>{{ user.first_name }} {{ user.last_name }}</h6>
                                <span class="text-muted d-flex">{{ user.email }} </span>
                              </div>
                            </div>
                          </td>
                          <td class="text-center f-13">{{ user.deletedBy }}</td>
                          <td class="text-center f-13">
                            {{ formatDateTime(user.deletedAt) }}
                          </td>
                          <td>
                            <button class="btn btn-primary-outline f-13" @click.prevent="unarchivedUser(user.user_id)" v-if="checked.length < 1">Unarchive</button>
                          </td>
                        </tr>
                      </tbody>
                      <NoData v-else :data="{ colspan: 5, message: 'No achieved members' }"></NoData>
                    </table>
                  </div>
                </div>
                <div class="t-pending-reg" v-if="paramMode == 2">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="tb-sd">Email Address</th>
                          <th>Role(s)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-if="users.users_result.length > 0">
                        <tr v-for="(user, index) in users.users_result" :key="index">
                          <td class="td-sd">
                            <div class="user-info">
                              <div class="user-details">
                                <!-- <h6>{{ user.first_name }} {{ user.last_name }}</h6> -->
                                <span class="text-muted d-flex">{{ user.email }} </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex flex-wrap w-100">
                              <div class="role" v-for="(role, index) in parsePermissions(user.role_id)" :key="index">
                                <span class="badge badge-mute" v-if="role == 4">Requester</span>

                                <span class="badge badge-mute" v-if="role == 14">Approver</span>
                                <span class="badge badge-mute" v-if="role == 24">Finance</span>
                                <span class="badge badge-mute" v-if="role == 34">Tech</span>
                                <span class="badge badge-mute" v-if="role == 44">Admin</span>
                                <span class="badge badge-mute" v-if="role == 54">Endorser</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button class="btn btn-primary-outline f-12 py-1" @click="resendMail(user)">Resend</button>
                          </td>
                        </tr>
                      </tbody>
                      <NoData v-else :data="{ colspan: 3, message: 'No members found' }"></NoData>
                    </table>
                  </div>
                </div>
                <div class="t-join-req" v-if="paramMode == 4">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="">Email</th>
                          <th class="">Date</th>
                          <th class="">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="users.users_result.length > 0">
                        <tr v-for="(user, index) in users.users_result" :key="index">
                          <td class="td-sd">
                            {{ user.email }}
                          </td>
                          <td class="f-13">
                            {{ formatDateTime(user.createdAt) }}
                          </td>
                          <td>
                            <div v-if="checked.length < 1">
                              <button class="btn btn-outline-success f-12 me-2 mt-1" data-bs-toggle="modal" data-bs-target="#approveUser" @click="selectedUser = user">
                                Accept
                              </button>
                              <button class="btn btn-outline-danger f-12 mt-1" @click="selectedUser = user" data-bs-toggle="modal" data-bs-target="#cancelUser">Reject</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <NoData v-else :data="{ colspan: 4, message: 'No requests found' }"></NoData>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation example" v-if="users.total > users.records_per_page" class="mt-3">
            <ul class="pagination justify-content-end">
              <li class="page-item" v-if="pagination.current != 1" :class="pagination.current == 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="getUsers(pagination.current - 1)" tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li class="page-item" v-for="(item, index) in pagination.pagLimit" :key="index" :class="item == pagination.current ? 'active' : ''">
                <a v-if="item <= pagination.last" class="page-link" @click.prevent="getUsers(item)" href="#">{{ item }}</a>
              </li>
              <li class="page-item" v-if="pagination.last > pagination.pagLimit">
                <select @change="nextPage($event)" class="select-page-link" :class="pagination.current > pagination.pagLimit ? 'active' : ''">
                  <option value="">...</option>
                  <option :value="item" v-for="(item, index) in pagination.last - pagination.pagLimit" :key="index">
                    {{ item + pagination.pagLimit }}
                  </option>
                </select>
              </li>
              <li class="page-item" v-if="pagination.current != pagination.last">
                <a class="page-link" @click="getUsers(pagination.current + 1)" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab" v-if="gloaded">
        <groups :staffs="teams" :groups="groups"></groups>
      </div>
    </div>
    <div class="modal fade modal-custom" id="manageAccess" tabindex="-1" aria-labelledby="manageAccessLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16.5" r="15.5" fill="#FAFAFF" stroke="#DFDFDF" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6253 12C12.6253 11.1049 12.9809 10.2464 13.6138 9.61351C14.2468 8.98058 15.1052 8.625 16.0003 8.625C16.8954 8.625 17.7539 8.98058 18.3868 9.61351C19.0197 10.2464 19.3753 11.1049 19.3753 12C19.3753 12.8951 19.0197 13.7536 18.3868 14.3865C17.7539 15.0194 16.8954 15.375 16.0003 15.375C15.1052 15.375 14.2468 15.0194 13.6138 14.3865C12.9809 13.7536 12.6253 12.8951 12.6253 12ZM9.81357 22.5787C9.83886 20.9546 10.5018 19.4055 11.6593 18.2659C12.8168 17.1263 14.376 16.4876 16.0003 16.4876C17.6247 16.4876 19.1839 17.1263 20.3413 18.2659C21.4988 19.4055 22.1618 20.9546 22.1871 22.5787C22.189 22.6881 22.1591 22.7956 22.1008 22.8882C22.0426 22.9808 21.9587 23.0544 21.8593 23.1C20.0212 23.9428 18.0224 24.3777 16.0003 24.375C13.9108 24.375 11.9256 23.919 10.1413 23.1C10.0419 23.0544 9.958 22.9808 9.89979 22.8882C9.84158 22.7956 9.81162 22.6881 9.81357 22.5787Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Add New Team Member</h5>
            </div>
            <p class="f-13 text-muted mt-3">Enter an email address and select the role(s) to add a new team member</p>
            <form @submit.prevent="addUser()" class="form">
              <div class="form-group">
                <label for="fname" class="mb-1 f-14">First Name</label>
                <input type="text" id="fname" class="form-control f-13" v-model="user.firstname" />
              </div>
              <div class="form-group">
                <label for="lname" class="mb-1 f-14">Last Name</label>
                <input type="text" id="lname" class="form-control f-13" v-model="user.lastname" />
              </div>
              <div class="form-group">
                <label for="phone" class="mb-1 f-14">Phone</label>
                <input type="text" id="phone" class="form-control f-13" v-model="user.phone" />
              </div>
              <div class="form-group">
                <label for="email" class="mb-1 f-14">Email <span class="required">*</span></label>
                <input type="email" id="email" class="form-control f-13" v-model="user.email" />
              </div>
              <div class="form-group">
                <label for="group" class="mb-1 f-14">Group </label>
                <multiselect v-model="group" :options="groups" placeholder="Select group" label="title"></multiselect>
              </div>
              <div class="form-group mt-2">
                <label for="" class="d-block mb-1 f-14">Select Role <span class="required">*</span></label>
                <div class="row ms-1">
                  <div class="form-check form-check-inline col-4 col-sm-3" v-for="role in roles" :key="role.id">
                    <input class="form-check-input bg-checked" type="radio" name="profile" :id="'profile' + role.id" :value="role.id" v-model="user.role_id" />
                    <label class="form-check-label" :for="'profile' + role.id">{{ role.role_name }}</label>
                    <!-- <input
                      class="form-check-input bg-checked"
                      type="checkbox"
                      :name="'profile'+ role.id"
                      :id="'profile' + role.id"
                      @change="addRole(role.id)"
                    />
                    <label
                      class="form-check-label f-14"
                      :for="'profile' + role.id"
                      >{{ role.role_name }}</label
                    >-->
                  </div>
                </div>
              </div>
              <div class="submit mt-3 d-flex justify-content-end">
                <button class="btn btn-darkBlue f-13" type="submit">Add Team Member</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="archiveMembers"
      tabindex="-1"
      aria-labelledby="archiveMembersLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 6.5C11.0386 6.5 10.1166 6.88192 9.43674 7.56174C8.75692 8.24156 8.375 9.16359 8.375 10.125C8.375 10.3902 8.48036 10.6446 8.66789 10.8321C8.85543 11.0196 9.10979 11.125 9.375 11.125C9.64022 11.125 9.89457 11.0196 10.0821 10.8321C10.2696 10.6446 10.375 10.3902 10.375 10.125C10.3753 9.83004 10.4559 9.54073 10.6082 9.28809C10.7604 9.03545 10.9786 8.82902 11.2392 8.69092C11.4998 8.55282 11.7932 8.48827 12.0877 8.50419C12.3822 8.52011 12.6669 8.61589 12.9111 8.78128C13.1554 8.94666 13.35 9.1754 13.4741 9.44297C13.5982 9.71054 13.6472 10.0069 13.6157 10.3001C13.5843 10.5934 13.4736 10.8726 13.2955 11.1078C13.1175 11.3429 12.8788 11.5252 12.605 11.635C11.929 11.905 11 12.597 11 13.75V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.756 13.05 13.634 13.261 13.53L13.348 13.49C14.1288 13.1759 14.776 12.6 15.1787 11.8609C15.5814 11.1219 15.7145 10.2658 15.5551 9.43939C15.3958 8.61299 14.9539 7.86776 14.3052 7.33147C13.6566 6.79518 12.8416 6.50123 12 6.5Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Archive Team Members</h5>
            </div>
            <div class="proceed f-14">
              <p class="my-4">Are you sure you want to archive all these team members?</p>
              <p class="mt-0 mb-5 d-flex align-items-center">
                <svg class="me-1" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99984 0.166992C3.94984 0.166992 0.666504 3.45033 0.666504 7.50033C0.666504 11.5503 3.94984 14.8337 7.99984 14.8337C12.0498 14.8337 15.3332 11.5503 15.3332 7.50033C15.3332 3.45033 12.0498 0.166992 7.99984 0.166992ZM7.6665 3.50033C7.48969 3.50033 7.32012 3.57056 7.1951 3.69559C7.07008 3.82061 6.99984 3.99018 6.99984 4.16699C6.99984 4.3438 7.07008 4.51337 7.1951 4.6384C7.32012 4.76342 7.48969 4.83366 7.6665 4.83366H7.99984C8.17665 4.83366 8.34622 4.76342 8.47124 4.6384C8.59627 4.51337 8.6665 4.3438 8.6665 4.16699C8.6665 3.99018 8.59627 3.82061 8.47124 3.69559C8.34622 3.57056 8.17665 3.50033 7.99984 3.50033H7.6665ZM6.6665 6.16699C6.48969 6.16699 6.32012 6.23723 6.1951 6.36226C6.07008 6.48728 5.99984 6.65685 5.99984 6.83366C5.99984 7.01047 6.07008 7.18004 6.1951 7.30506C6.32012 7.43009 6.48969 7.50033 6.6665 7.50033H7.33317V9.50033H6.6665C6.48969 9.50033 6.32012 9.57056 6.1951 9.69559C6.07008 9.82061 5.99984 9.99018 5.99984 10.167C5.99984 10.3438 6.07008 10.5134 6.1951 10.6384C6.32012 10.7634 6.48969 10.8337 6.6665 10.8337H9.33317C9.50998 10.8337 9.67955 10.7634 9.80458 10.6384C9.9296 10.5134 9.99984 10.3438 9.99984 10.167C9.99984 9.99018 9.9296 9.82061 9.80458 9.69559C9.67955 9.57056 9.50998 9.50033 9.33317 9.50033H8.6665V6.83366C8.6665 6.65685 8.59627 6.48728 8.47124 6.36226C8.34622 6.23723 8.17665 6.16699 7.99984 6.16699H6.6665Z"
                    fill="#022438"
                  />
                </svg>
                The team member will lose access to this organization.
              </p>
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary f-14" @click.prevent="archiveUsers()">Archive Members</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="unarchiveMembers"
      tabindex="-1"
      aria-labelledby="unarchiveMembersLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 6.5C11.0386 6.5 10.1166 6.88192 9.43674 7.56174C8.75692 8.24156 8.375 9.16359 8.375 10.125C8.375 10.3902 8.48036 10.6446 8.66789 10.8321C8.85543 11.0196 9.10979 11.125 9.375 11.125C9.64022 11.125 9.89457 11.0196 10.0821 10.8321C10.2696 10.6446 10.375 10.3902 10.375 10.125C10.3753 9.83004 10.4559 9.54073 10.6082 9.28809C10.7604 9.03545 10.9786 8.82902 11.2392 8.69092C11.4998 8.55282 11.7932 8.48827 12.0877 8.50419C12.3822 8.52011 12.6669 8.61589 12.9111 8.78128C13.1554 8.94666 13.35 9.1754 13.4741 9.44297C13.5982 9.71054 13.6472 10.0069 13.6157 10.3001C13.5843 10.5934 13.4736 10.8726 13.2955 11.1078C13.1175 11.3429 12.8788 11.5252 12.605 11.635C11.929 11.905 11 12.597 11 13.75V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.756 13.05 13.634 13.261 13.53L13.348 13.49C14.1288 13.1759 14.776 12.6 15.1787 11.8609C15.5814 11.1219 15.7145 10.2658 15.5551 9.43939C15.3958 8.61299 14.9539 7.86776 14.3052 7.33147C13.6566 6.79518 12.8416 6.50123 12 6.5Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Unarchive Team Members</h5>
            </div>
            <div class="proceed f-14">
              <p class="my-4">Are you sure you want to unarchive all these members?</p>

              <div class="d-flex justify-content-end">
                <button class="btn btn-primary f-14" @click.prevent="unarchivedUser(-1)">Unarchive Members</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal-custom" id="approveUser" tabindex="-1" aria-labelledby="approveUserLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 6.5C11.0386 6.5 10.1166 6.88192 9.43674 7.56174C8.75692 8.24156 8.375 9.16359 8.375 10.125C8.375 10.3902 8.48036 10.6446 8.66789 10.8321C8.85543 11.0196 9.10979 11.125 9.375 11.125C9.64022 11.125 9.89457 11.0196 10.0821 10.8321C10.2696 10.6446 10.375 10.3902 10.375 10.125C10.3753 9.83004 10.4559 9.54073 10.6082 9.28809C10.7604 9.03545 10.9786 8.82902 11.2392 8.69092C11.4998 8.55282 11.7932 8.48827 12.0877 8.50419C12.3822 8.52011 12.6669 8.61589 12.9111 8.78128C13.1554 8.94666 13.35 9.1754 13.4741 9.44297C13.5982 9.71054 13.6472 10.0069 13.6157 10.3001C13.5843 10.5934 13.4736 10.8726 13.2955 11.1078C13.1175 11.3429 12.8788 11.5252 12.605 11.635C11.929 11.905 11 12.597 11 13.75V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.756 13.05 13.634 13.261 13.53L13.348 13.49C14.1288 13.1759 14.776 12.6 15.1787 11.8609C15.5814 11.1219 15.7145 10.2658 15.5551 9.43939C15.3958 8.61299 14.9539 7.86776 14.3052 7.33147C13.6566 6.79518 12.8416 6.50123 12 6.5Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Approve Join Request</h5>
            </div>
            <form @submit.prevent="approveUser()" class="form mt-3">
              <div class="form-group">
                <label for="" class="f-14"> Email </label>
                <input type="email" disabled class="form-control f-14" v-model="selectedUser.email" />
              </div>
              <div class="form-group">
                <label for="" class="f-14">Role</label>
                <div class="row ms-1">
                  <div class="form-check form-check-inline col-4 col-sm-3" v-for="role in roles" :key="role.id">
                    <input class="form-check-input bg-checked" type="radio" name="roles" :id="'roles' + role.id" :value="role.id" v-model="selectedUser.role_id" />
                    <label class="form-check-label f-14" :for="'roles' + role.id">{{ role.role_name }}</label>
                  </div>
                </div>
              </div>
              <div class="submit mt-4 d-flex justify-content-end">
                <button class="btn btn-primary f-14 py-2" type="submit">Approve User</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal-custom" id="cancelUser" tabindex="-1" aria-labelledby="cancelUserLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
                <path d="M25.5 15L15.5 25M15.5 15L25.5 25" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#DFDFDF" />
              </svg>
            </button>
          </div>

          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16ZM12 6.5C11.0386 6.5 10.1166 6.88192 9.43674 7.56174C8.75692 8.24156 8.375 9.16359 8.375 10.125C8.375 10.3902 8.48036 10.6446 8.66789 10.8321C8.85543 11.0196 9.10979 11.125 9.375 11.125C9.64022 11.125 9.89457 11.0196 10.0821 10.8321C10.2696 10.6446 10.375 10.3902 10.375 10.125C10.3753 9.83004 10.4559 9.54073 10.6082 9.28809C10.7604 9.03545 10.9786 8.82902 11.2392 8.69092C11.4998 8.55282 11.7932 8.48827 12.0877 8.50419C12.3822 8.52011 12.6669 8.61589 12.9111 8.78128C13.1554 8.94666 13.35 9.1754 13.4741 9.44297C13.5982 9.71054 13.6472 10.0069 13.6157 10.3001C13.5843 10.5934 13.4736 10.8726 13.2955 11.1078C13.1175 11.3429 12.8788 11.5252 12.605 11.635C11.929 11.905 11 12.597 11 13.75V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14C13 13.756 13.05 13.634 13.261 13.53L13.348 13.49C14.1288 13.1759 14.776 12.6 15.1787 11.8609C15.5814 11.1219 15.7145 10.2658 15.5551 9.43939C15.3958 8.61299 14.9539 7.86776 14.3052 7.33147C13.6566 6.79518 12.8416 6.50123 12 6.5Z"
                  fill="#022438"
                />
              </svg>

              <h5 class="mb-0 ms-2 text-primary">Cancel Join Request</h5>
            </div>
            <div class="proceed f-14">
              <p class="my-4">Are you sure you want to cancel this join request?</p>
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary f-14" @click.prevent="cancelUser()">Cancel Request</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/dashboard/noData.vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
// import "vue-multiselect/dist/vue-multiselect.min.css";
import groups from '../../../components/dashboard/teams/groups.vue';
export default {
  components: {
    NoData,
    groups,
    Multiselect,
  },
  data() {
    return {
      showGroup: true,
      user: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        role_id: '4',
        role_ids: [],
        group_id: '',
      },
      teams: [],
      group: null,
      roles: [],
      loaded: false,
      gloaded: false,
      usersExportLoading: false,
      disabledCheck: true,
      payments: [],
      users: [],
      search: '',
      loading: false,
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
      paramMode: 5,
      checked: [],
      checkedAllStatus: false,
      selectedUser: {},
      groups: [],
    };
  },
  methods: {
    toggleShowGroup(value) {
      this.showGroup = value;
    },

    unarchivedUser(id) {
      var data = {};
      if (!id) {
        window.ToasterAlert('error', 'Invalid user');
        return false;
      } else if (id == -1) {
        data = {
          user_ids: this.checked,
        };
      } else {
        data = {
          user_id: id,
        };
      }
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/restore/' + this.$store.state.user.id,
          details: data,
        })
        .then(() => {
          window.ToasterAlert('success', 'Team member unarchived');

          setTimeout(function () {
            window.location.reload();
          }, 2000);
        });
    },

    checkAll() {
      if (this.checkedAllStatus) {
        this.checked = [];
      } else {
        this.users.users_result.forEach((item) => {
          this.checked.push(item.user_id);
        });
      }
      this.checkedAllStatus = !this.checkedAllStatus;
    },
    addChecked(id) {
      if (id) {
        if (this.checked.includes(id)) {
          this.checked.splice(
            this.checked.findIndex((item) => item == id),
            1
          );
        } else {
          this.checked.push(id);
        }
      } else {
        window.ToasterAlert('error', 'Invalid user');
      }
    },
    addRole(id) {
      if (this.user.role_ids.includes(id)) {
        this.user.role_ids.splice(
          this.user.role_ids.findIndex((item) => item == id),
          1
        );
      } else {
        this.user.role_ids.push(id);
      }
    },
    archiveUsers() {
      this.$store.commit('setLoader', true);
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: `users/delete/${this.$store.state.user.id}`,
          details: { user_ids: this.checked },
        })
        .then(() => {
          window.ToasterAlert('success', 'Member archived');
          setTimeout(function () {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    resendMail(user) {
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: `users/createusermail/${this.$store.state.user.user_id}`,
          details: { email: user.email },
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'Invite email sent');
        })
        .catch(() => {
          this.$store.commit('setLoader', false);
        });
    },
    havePermission(user, value) {
      const permit = this.parsePermissions(user.role_id);
      return permit.some((item) => {
        return item == value;
      });
    },
    addUser() {
      if (this.user.email.length < 1) {
        window.SweetAlert('error', 'Team Member email is required');
        return false;
      }
      if (this.group) {
        this.user.group_id = this.group.id;
      }
      window.$('#manageAccess').modal('hide');
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/create/' + this.$store.state.user.user_id,
          details: this.user,
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'Invite sent to team member');
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    updateRole(user, role) {
      var roles = this.parsePermissions(user.role_id);
      var check = roles.find((item) => {
        return item == role;
      });
      if (check) {
        roles.splice(
          roles.findIndex((item) => item == role),
          1
        );
      } else {
        roles.push(role);
      }

      let permit = roles.join(',');
      this.users.users_result.forEach((item) => {
        if (item.user_id == user.user_id) {
          item.role_id = permit;
        }
      });
      if (permit[0] == ',') {
        permit = permit.replace(',', '');
      }
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/approveusers/' + this.$store.state.user.id,
          details: {
            user_id: user.user_id,
            role_id: permit,
          },
        })
        .then(() => {
          this.$store.commit('setLoader', false);

          window.window.SweetAlert('success', 'Team Member role updated successfully');
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },

    exportToExcel() {
      this.usersExportLoading = true;
      axios.get(this.$store.state.endpoint + 'company/report/allusers/' + this.$store.state.user.user_id, { responseType: 'blob' }).then((res) => {
        this.usersExportLoading = false;
        var FILE = window.URL.createObjectURL(new Blob([res.data]));

        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', 'users.xlsx');
        window.document.body.appendChild(docUrl);
        docUrl.click();
      });
    },

    nextPage(no) {
      var cal = Number(no.target.value) + Number(this.pagination.pagLimit);
      this.getUsers(cal);
    },
    async getUsers(page = 1) {
      this.checked = [];
      this.checkedAllStatus = false;
      this.$store.commit('setLoader', true);
      await this.$store.dispatch('get', `users/company/all/${this.$store.state.user.user_id}?page=${page}&mode=${this.paramMode}&search=${this.search}`).then((resp) => {
        this.$store.commit('setLoader', false);
        this.users = resp.data;
        this.pagination.current = page;
        this.pagination.last = Math.ceil(this.users.total / this.users.records_per_page);
        this.loaded = true;
      });
    },
    getRoles() {
      this.$store.dispatch('get', 'users/roles').then((resp) => {
        this.roles = resp.data;
      });
    },
    setPagination(page) {
      this.pagination.current = page;
      this.pagination.last = Math.ceil(this.users.total / this.users.records_per_page);
    },
    paginationClick(page) {
      this.getUsers(page);
    },
    approveUser() {
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/approveusers/' + this.$store.state.user.id,
          details: {
            // user_id: this.selectedUser.user_id,
            // role_id: this.selectedUser.role_id,
            users: [
              {
                id: this.selectedUser.user_id,
                role_id: this.selectedUser.role_id,
              },
            ],
          },
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'User added');
          this.users.users_result.splice(
            this.users.users_result.findIndex((item) => item.id == this.selectedUser.id),
            1
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    cancelUser() {
      window.$('#cancelUser').modal('hide');
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/rejectusers/' + this.$store.state.user.id,
          details: {
            user_id: this.selectedUser.id,
          },
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'User request cancelled successfully');
          this.users.users_result.splice(
            this.users.users_result.findIndex((item) => item.id == this.selectedUser.id),
            1
          );
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    getAllTeamMembers() {
      this.$store.dispatch('get', `users/activeusers/${this.$store.state.user.user_id}`).then((resp) => {
        this.teams = resp.data;
        this.gloaded = true;
      });
    },
    getGroups() {
      this.$store.dispatch('get', `company/groups/${this.$store.state.user.id}`).then((resp) => {
        this.groups = resp.data;
      });
    },
  },
  async created() {
    if (this.$route.query.mode) {
      this.paramMode = this.$route.query.mode;
    }

    this.getRoles();
    this.getGroups();
    this.getAllTeamMembers();
    await this.getUsers();
  },
};
</script>

<style>
.vue-popover {
  top: auto !important;
  left: auto !important;
}
.table-filter .multiselect__placeholder {
  margin-bottom: 0 !important;
}
.table-filter .col- .table-filter .multiselect__tags {
  min-height: 38px;
  padding: 6px 40px 0 8px;
  border-radius: 0;
}
.table-filter .multiselect__input,
.table-filter .multiselect__single {
  font-size: 13px;
  line-height: 22px;
}

#manageAccess .modal-body .multiselect__tags {
  min-height: 40px !important;
  border: 1px solid #ced4da;
}
#manageAccess .multiselect__placeholder {
  margin-bottom: 0;
}
#manageAccess .multiselect__input,
#manageAccess .multiselect__single {
  color: #012538;
  min-height: 0 !important;
  font-size: 14px;
}
</style>

<style scoped lang="scss">
.table {
  margin-bottom: 0;
}
.t-archived {
  table tbody {
    .btn {
      padding: 4px 13px;
    }
  }
}
.tab-wrapper {
  padding: 0;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}
.btn-outline {
  color: #022438;
  width: 140px;
  padding-inline: 1rem;
  border-radius: 8px;
  border: 1px solid #022438;
}
.card.b-card {
  border-radius: 0 0 8px 8px;
  border: 0;
  width: 100%;
}

.mb-36 {
  margin-bottom: 36px;
}

.role .badge {
  margin-right: 5px;
  font-size: 10px;
}
.btn-darkBlue {
  color: #fff;
  border-radius: 8px;
  background: #022438;
}
.set-box {
  padding: 16px 14px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #dfdfdf;
  background: #fafaff;
  .nav-pills .nav-link.active {
    background: #022438;
    color: #fff;
    border-bottom: 0;
  }

  .nav-pills {
    .nav-link {
      padding: 13px 22px;
    }
    .nav-item:first-child .nav-link {
      border-top-left-radius: 8px;
    }
  }
}
.second-title {
  color: #022438;
  font-size: 17px;
  font-weight: 400;
}
.second-details {
  color: #6a6a6a;
  margin-bottom: 0;
  font-size: 14px;
}
.btn-filter {
  border-radius: 6px !important;
  font-size: 14px;
  font-weight: 400;
  color: #9f9f9f;
  border: 1px solid #9f9f9f;
  gap: 12px;
  height: 40px !important;
  padding: 8px 14px !important;
}
.input-group {
  border-radius: 8px;
}
.input-group .form-control {
  border: 1px solid #dfdfdf;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.input-group .input-group-text {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 14px;
}
.nav-tab {
  border: 1px solid #dfdfdf;
  border-top: none;
  background: #fff;
  padding: 0 14px;
  gap: 1.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #022438;
  background-color: none;
  border-bottom: 3px solid #022438;
}

.nav-pills .nav-link {
  color: #022438;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 12px 13px 5px;
  font-size: 14px;
}
.user-action {
  .btn-view {
    color: #012538 !important;
  }
}
.user-info {
  display: flex;
  align-items: center;
  color: black;
  figure {
    width: 35px;
    margin: 0 7px 0 0;
  }
  .user-details {
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: capitalize;
    }
    span {
      font-size: 12px;
    }
  }
}
.mk-check {
  border-radius: 4px;
}
.p-0 {
  padding: 0 !important;
}
table thead tr th:last-child {
  border-radius: 0 !important;
}
table thead tr th {
  color: #5a5f7d;
  background: #f8f9fb;
  font-size: 13px;
}
table thead svg {
  cursor: pointer;
}
.btn-add {
  font-size: 14px;
  border-radius: 0;
}
.btn-close {
  font-size: 14px;
}
.team-header {
  border: 1px solid rgb(236, 236, 236);
  border-top: 0;
  background: #fff;
  padding: 9px 14px;
}
.main-search {
  font-size: 14px;
  width: 20em;
}
.main-search input {
  height: 37px;
}
.t-badge .badge {
  margin-right: 3px;
}
.manage .form-group {
  margin-bottom: 10px;
}
input[type='checkbox'] {
  background: #fff;
}
@media (max-width: 768px) {
  .manage-top-btns {
    margin-top: 10px;
  }

  .search,
  .main-search {
    width: 100%;
  }
}
// .recur-con {
//   max-width: 26px;
//   max-height: 32px;
//   position: relative;

//   .recur-svg {
//     width: 24px;
//   }
.recur-no {
  right: 6px;
  // margin: 0;
}
// }

.circle-pp {
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #012538;
  color: #fff;
  justify-content: center;
  align-items: center;
  i {
    font-size: 13px;
  }
}
.payee-control {
  border-radius: 0;
  border: 1px solid #e8e8e8;
}
table thead tr th {
  color: #5a5f7d;
  background: #f8f9fb;
  padding: 16px 7px;
}
tbody td {
  padding: 16px 7px;
}
.table-filter .t-col {
  margin-bottom: 10px;
}
.rqd-inputs {
  display: flex;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 8px 10px;
  font-size: 13px;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
  input {
    border: none;
    box-shadow: none;
    outline: none;
  }
}
.btn-filter {
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;
  button {
    border-radius: 0;
  }
}
.btn-add {
  font-size: 14px;
  border-radius: 0;
}
.btn-close {
  font-size: 14px;
}
/* .search {
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  height: 40px;
  width: 20em;
} */
.t-badge .badge {
  margin-right: 3px;
}
.manage .form-group {
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .btn-add,
  .btn-filter {
    font-size: 11px;
  }
  .btn-outline {
    font-size: 11px;
    width: fit-content;
  }
  .set-box {
    flex-wrap: wrap;
  }
  .main-search {
    margin-top: 14px;
  }
}
</style>
