<template>
  <div class="login-wrapper">
    <div class="d-flex">
      <div class="banner-left">
        <router-link to="/" class="side-logo">
          <img src="@/assets/img/aaa.png" class="w-100" alt="Buysimply logo" />
        </router-link>
        <div class="side-img">
          <img src="@/assets/img/13.png" class="w-100" alt="" />
        </div>
        <div class="side-footer">
          <div class="indicator">
            <div class="dot active"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <h4>Expense management made easy for you</h4>
          <p>Create an account now and start your seamless process of expense tracking in your organization</p>
        </div>
      </div>
      <div class="login-section-wrapper">
        <router-link to="/" class="logo mb-4">
          <img src="@/assets/img/logo.png" alt="logo" class="w-100" />
        </router-link>
        <div class="login-section pt-0">
          <div class="tab-section w-100">
            <ul class="nav nav-pills mb-3 justify-content-between" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {{ existing_user ? 'Add New Organisation' : 'New Organisation' }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Join Existing Organisation
                </button>
              </li>
            </ul>
          </div>
          <!-- <div class="login-header">
            <h3 class="text-center">Sign Up</h3>
            <p class="lh-descp">
              Create a new account to start a new simplified buying experience
              for your business
            </p>
          </div> -->
          <div class="login-body mt-3 tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="rg-header">
                <h5 class="text-primary">Create an Account</h5>
                <p style="font-size: 14px; color: #9f9f9f">Fill in the required information to get started</p>
                <div class="divider mb-3"></div>
              </div>
              <form @submit.prevent="register()">
                <div class="form-group">
                  <label for="">Organisation Name <span class="required">*</span></label>
                  <input type="text" class="form-control" placeholder="Organisation name" v-model="business.company_name" />
                </div>
                <div class="form-group">
                  <label for="">Organisation Address <span class="required">*</span></label>
                  <input type="text" class="form-control" placeholder="Organisation address" v-model="business.address" />
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Country <span class="required">*</span></label>
                      <multiselect v-model="country" :options="countries" label="name"> </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Organisation Type <span class="required">*</span></label>
                      <select name="" id="" class="form-control" v-model="business.type">
                        <option :value="item.name" v-for="(item, index) in orgType" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                      <!-- <input
                        type="text"
                        v-model="business.type"
                        class="form-control"
                      /> -->
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">First Name <span class="required">*</span></label>
                      <input type="text" v-model="business.first_name" :disabled="existing_user" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Last Name <span class="required">*</span></label>
                      <input type="text" v-model="business.last_name" :disabled="existing_user" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Email Address <span class="required">*</span></label>
                      <input type="email" v-model="business.email" class="form-control" placeholder="Email" :disabled="disableEmail || existing_user" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Phone <span class="required">*</span></label>
                      <vue-tel-input v-model="business.phone" v-bind="bindProps"></vue-tel-input>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="!existing_user">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Password <span class="required">*</span></label>
                      <div class="input-group">
                        <input :type="pswType" class="form-control input-psw" placeholder="Password" v-model="business.password" @input="checkPassword" required />
                        <span class="input-group-text" id="togglePsw" @click.prevent="togglePsw()">
                          <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                          <i class="fa fa-eye-slash" v-else></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="">Confirm Password <span class="required">*</span></label>
                      <input type="password" v-model="business.confirm_password" class="form-control mb-2" placeholder="Confirm Password" />
                    </div>
                  </div>
                </div>
                <div class="form-group" v-if="!existing_user" style="margin-top: -17px">
                  <div class="psw-checker">
                    <ul>
                      <li
                        v-bind:class="{
                          is_valid: contains_eight_characters,
                        }"
                      >
                        <svg
                          v-if="!contains_eight_characters"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 241.171 241.171"
                          style="enable-background: new 0 0 241.171 241.171; width: 10px"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              id="Close"
                              d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                              fill="#000000"
                              style="fill: rgb(220, 54, 70)"
                            ></path>
                          </g>
                        </svg>
                        <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                            fill="rgb(0,0,0)"
                            fill-rule="evenodd"
                            style="fill: rgb(25, 134, 85)"
                          ></path>
                        </svg>

                        <span> at least 8 characters</span>
                      </li>
                      <li v-bind:class="{ is_valid: contains_number }">
                        <svg
                          v-if="!contains_number"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 241.171 241.171"
                          style="enable-background: new 0 0 241.171 241.171; width: 10px"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              id="Close"
                              d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                              fill="#000000"
                              style="fill: rgb(220, 54, 70)"
                            ></path>
                          </g>
                        </svg>

                        <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                            fill="rgb(0,0,0)"
                            fill-rule="evenodd"
                            style="fill: rgb(25, 134, 85)"
                          ></path>
                        </svg>

                        <span> one number</span>
                      </li>
                      <li v-bind:class="{ is_valid: contains_uppercase }">
                        <svg
                          v-if="!contains_uppercase"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 241.171 241.171"
                          style="enable-background: new 0 0 241.171 241.171; width: 10px"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              id="Close"
                              d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                              fill="#000000"
                              style="fill: rgb(220, 54, 70)"
                            ></path>
                          </g>
                        </svg>

                        <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                            fill="rgb(0,0,0)"
                            fill-rule="evenodd"
                            style="fill: rgb(25, 134, 85)"
                          ></path>
                        </svg>

                        <span> one uppercase letter</span>
                      </li>
                      <li
                        v-bind:class="{
                          is_valid: contains_special_character,
                        }"
                      >
                        <svg
                          v-if="!contains_special_character"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 241.171 241.171"
                          style="enable-background: new 0 0 241.171 241.171; width: 10px"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              id="Close"
                              d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                              fill="#000000"
                              style="fill: rgb(220, 54, 70)"
                            ></path>
                          </g>
                        </svg>

                        <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clip-rule="evenodd"
                            d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                            fill="rgb(0,0,0)"
                            fill-rule="evenodd"
                            style="fill: rgb(25, 134, 85)"
                          ></path>
                        </svg>

                        <span> one symbol (e.g. !@#$%^&*~_+-)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-group" v-if="!existing_user">
                  <label for="">Referral Code</label>
                  <input type="text" v-model="business.ref_code" class="form-control mb-2" placeholder="Enter Referral Code" />
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary w-100 submit" :disabled="loading">
                    <span v-if="!loading">
                      {{ showCompanyName ? 'Create Organisation Account' : 'Join Organisation' }}
                    </span>
                    <span v-else>Loading...</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <form>
                <div class="stage1" v-if="stage == 1">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Company ID" v-model="business.company_slug" />
                  </div>
                  <div class="form-group">
                    <input type="email" v-model="business.email" class="form-control" placeholder="Email" :disabled="disableEmail || existing_user" />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary w-100 submit" :disabled="loading" @click.prevent="checkEmail()">
                      <span v-if="!loading"> Next </span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </div>
                <div class="stage2" v-if="stage == 2">
                  <p class="alert alert-info f-13">Email already belongs to an organisation, please enter the password to continue</p>
                  <div class="form-group">
                    <label class="f-14">Password</label>
                    <div class="input-group">
                      <input :type="pswType" class="form-control input-psw" placeholder="Password" v-model="business.password" @input="checkPassword" required />
                      <span class="input-group-text" id="togglePsw" @click.prevent="togglePsw()">
                        <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                        <i class="fa fa-eye-slash" v-else></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary w-100 submit" :disabled="loading" @click.prevent="register(1)">
                      <span v-if="!loading"> Join Organisation </span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </div>
                <div class="stage3" v-if="stage == 3">
                  <div class="form-group">
                    <div class="input-group">
                      <input :type="pswType" class="form-control input-psw" placeholder="Password" v-model="business.password" @input="checkPassword" required />
                      <span class="input-group-text" id="togglePsw" @click.prevent="togglePsw()">
                        <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                        <i class="fa fa-eye-slash" v-else></i>
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="password" v-model="business.confirm_password" class="form-control mb-2" placeholder="Confirm Password" />
                    <div class="psw-checker">
                      <ul>
                        <li
                          v-bind:class="{
                            is_valid: contains_eight_characters,
                          }"
                        >
                          <svg
                            v-if="!contains_eight_characters"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 241.171 241.171"
                            style="enable-background: new 0 0 241.171 241.171; width: 10px"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                id="Close"
                                d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                                fill="#000000"
                                style="fill: rgb(220, 54, 70)"
                              ></path>
                            </g>
                          </svg>
                          <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              clip-rule="evenodd"
                              d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                              fill="rgb(0,0,0)"
                              fill-rule="evenodd"
                              style="fill: rgb(25, 134, 85)"
                            ></path>
                          </svg>

                          <span> at least 8 characters</span>
                        </li>
                        <li v-bind:class="{ is_valid: contains_number }">
                          <svg
                            v-if="!contains_number"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 241.171 241.171"
                            style="enable-background: new 0 0 241.171 241.171; width: 10px"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                id="Close"
                                d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                                fill="#000000"
                                style="fill: rgb(220, 54, 70)"
                              ></path>
                            </g>
                          </svg>

                          <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              clip-rule="evenodd"
                              d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                              fill="rgb(0,0,0)"
                              fill-rule="evenodd"
                              style="fill: rgb(25, 134, 85)"
                            ></path>
                          </svg>

                          <span> one number</span>
                        </li>
                        <li v-bind:class="{ is_valid: contains_uppercase }">
                          <svg
                            v-if="!contains_uppercase"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 241.171 241.171"
                            style="enable-background: new 0 0 241.171 241.171; width: 10px"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                id="Close"
                                d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                                fill="#000000"
                                style="fill: rgb(220, 54, 70)"
                              ></path>
                            </g>
                          </svg>

                          <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              clip-rule="evenodd"
                              d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                              fill="rgb(0,0,0)"
                              fill-rule="evenodd"
                              style="fill: rgb(25, 134, 85)"
                            ></path>
                          </svg>

                          <span> one uppercase letter</span>
                        </li>
                        <li
                          v-bind:class="{
                            is_valid: contains_special_character,
                          }"
                        >
                          <svg
                            v-if="!contains_special_character"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 241.171 241.171"
                            style="enable-background: new 0 0 241.171 241.171; width: 10px"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                id="Close"
                                d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                                fill="#000000"
                                style="fill: rgb(220, 54, 70)"
                              ></path>
                            </g>
                          </svg>

                          <svg v-else fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              clip-rule="evenodd"
                              d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                              fill="rgb(0,0,0)"
                              fill-rule="evenodd"
                              style="fill: rgb(25, 134, 85)"
                            ></path>
                          </svg>

                          <span> one symbol (e.g. !@#$%^&*~_+-)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary w-100 submit" :disabled="loading" @click.prevent="register()">
                      <span v-if="!loading">
                        {{ showCompanyName ? 'Create Organisation account' : 'Join Organisation' }}
                      </span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="forgot-psw d-flex justify-content-between mb-4">
            <div class="remember-me"></div>
            <router-link :to="{ name: 'login' }" class="rec-psw">Already have an account? Login here</router-link>
          </div>
        </div>
      </div>

      <div class="modal fade modal-custom" id="accCreated" tabindex="-1" aria-labelledby="accCreatedLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mcustom-header d-flex align-items-center">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 50 50"
                  style="enable-background: new 0 0 50 50; width: 22px"
                  xml:space="preserve"
                >
                  <circle style="fill: rgb(25, 134, 85)" cx="25" cy="25" r="25" fill="#25AE88"></circle>
                  <polyline
                    style="fill: none; stroke-width: 2; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10"
                    points=" 38,15 22,33 12,25 "
                    stroke="#FFFFFF"
                  ></polyline>
                </svg>
                <h5 class="mb-0 ms-2 text-success">Organization account created</h5>
              </div>
              <div class="proceed">
                <p class="my-4">Your organization's account has been successfully created. Please complete the setup process to ensure that you have a great experience.</p>
                <a class="btn btn-primary f-14" href="/onboarding"> Proceed to Account Set-Up </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
<script type="text/javascript">
(function (t, a, p) {
  t.TapfiliateObject = a;
  t[a] =
    t[a] ||
    function () {
      (t[a].q = t[a].q || []).push(arguments);
    };
})(window, 'tap');

tap('create', '45795-1e389b', { integration: 'javascript' });
tap('detect');
</script>
<script>
import countriesData from '@/data/country.json';
import Multiselect from 'vue-multiselect';
import organizationType from '@/data/organizationType.json';
import { VueTelInput } from 'vue-tel-input';
export default {
  components: {
    Multiselect,
    VueTelInput,
  },
  data() {
    return {
      orgType: [],
      countries: [],
      country: {},
      bindProps: {
        mode: 'international',
        defaultCountry: 234,
        invalidMsg: 'Invalid phone number',
        inputOptions: {
          showDialCode: true,
        },
        validCharactersOnly: true,
      },
      business: {
        company_name: '',
        type: '',
        country: '',
        first_name: '',
        last_name: '',
        address: '',
        company_slug: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        ref_code: '',
      },
      loading: false,
      disableEmail: false,
      showCompanyName: true,
      pswType: 'password',

      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      stage: 1,
      existing_user: false,
      joinReq: false,
    };
  },
  methods: {
    checkEmail() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'users/checkUser',
          details: { email: this.business.email },
        })
        .then((resp) => {
          this.loading = false;
          if (resp.data == 0) {
            this.stage = 3;
            this.showCompanyName = false;
            this.joinReq = true;
          } else {
            this.stage = 2;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    checkPassword() {
      /* eslint-disable no-useless-escape */
      this.password_length = this.business.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 7) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.business.password);
      this.contains_uppercase = /[A-Z]/.test(this.business.password);
      this.contains_special_character = format.test(this.business.password);

      if (this.contains_eight_characters === true && this.contains_special_character === true && this.contains_uppercase === true && this.contains_number === true) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    register(type = null) {
      if (!this.joinReq && type != 1) {
        if (this.business.company_name.length < 1 && this.business.company_slug.length < 1) {
          window.ToasterAlert('error', 'Organisation name  is required');
          return false;
        }
        if (this.business.address.length < 1) {
          window.ToasterAlert('error', 'Organisation address is required');
          return false;
        }
        if (this.business.type.length < 1) {
          window.ToasterAlert('error', 'Organisation type is required');
          return false;
        }
        if (this.business.phone.length < 5) {
          window.ToasterAlert('error', 'Invalid phone number');
          return false;
        }
        if (Object.keys(this.country).length === 0) {
          window.ToasterAlert('error', 'Country is required');
          return false;
        }
        this.business.country = this.country.name;
      }
      if (!this.existing_user) {
        if (type == 1) {
          this.business.confirm_password = this.business.password;
        }
        if (this.business.email.length < 1 || this.business.password.length < 1) {
          window.SweetAlert('error', 'All input fields are required');
          return false;
        }
        // if (
        //   this.business.company_name.length < 1 &&
        //   this.business.company_slug.length < 1
        // ) {
        //   window.SweetAlert(
        //     "error",
        //     "Organisation name or Organisation ID field is required"
        //   );
        //   return false;
        // }
        if (!type && !this.valid_password) {
          window.ToasterAlert('error', 'Password not strong enough');
          return false;
        }
        if (this.business.confirm_password != this.business.password) {
          window.ToasterAlert('error', 'Password and Confirm Password does not match');
          return false;
        }
      }
      this.loading = true;

      var endpoint = 'users/signup';
      if (this.$route.query.add_organization == 1) {
        endpoint = 'users/signup/existing';
        delete this.business.company_slug;
      }
      this.$store
        .dispatch('post', { endpoint: endpoint, details: this.business })
        .then((resp) => {
          this.loading = false;
          this.business.company_name = '';
          this.business.company_slug = '';
          this.business.email = '';
          this.business.password = '';
          this.$store.commit('setUser', resp.data);
          // window.ToasterAlert("success", "User created successfully");
          if (resp.data.user.role_id == 0) {
            window.SweetAlert('info', 'Please wait while admin approve your request');
            this.$router.push('/pending');
          }
          window.$('#accCreated').modal('show');
          // this.$router.push("/dashboard");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    togglePsw() {
      this.pswType == 'password' ? (this.pswType = 'text') : (this.pswType = 'password');
    },
  },
  mounted() {
    if (this.$route.query.add_organization == 1) {
      this.$store.commit('getUser');
      this.existing_user = true;
      this.business.first_name = this.$store.state.user.first_name;
      this.business.last_name = this.$store.state.user.last_name;
      this.business.email = this.$store.state.user.email;
    }

    // console.log(this.existing_user);
  },
  created() {
    this.orgType = organizationType;
    this.countries = countriesData;
    window.fbq('trackCustom', 'RegisterPage');
    if (this.$route.query.email) {
      this.business.email = this.$route.query.email;
      this.disableEmail = true;
    }
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
.login-section-wrapper .login-body .multiselect__tags {
  min-height: 41px;
  border-radius: 8px;
  border: 1px solid #ced4da;
}
.login-section-wrapper .vti__input {
  border-radius: 0 8px 8px 0;
}
.login-section-wrapper .vue-tel-input {
  border-radius: 8px;
  height: 41px;
}
</style>
<style scoped lang="scss">
.show-link {
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  color: #0d6efd;
  font-size: 13px;
}
.proceed {
  color: #848484;
}
.img-row {
  background-color: #2d68cba3;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-wrapper {
    width: 78%;
  }
}
.login-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  .logo {
    margin-top: 30px;
    width: 150px;
  }
  label {
    color: #848484;
    font-size: 14px;
  }

  .login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .login-header {
      .lh-descp {
        max-width: 25em;
        text-align: center;
        color: grey;
        font-size: 14px;
      }
    }
    .tab-section {
      .nav-item {
        width: 50%;
      }
      .nav-pills .nav-link {
        width: 100%;
        border-radius: 0;
        color: #022438;
        font-weight: 500;
        font-size: 14px;
        background: #8080801a;
        padding: 10px 0;
      }
      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        background: #022438;
        color: #fff;
        font-weight: bold;
      }
    }
    .login-body {
      width: 100%;
      padding: 0 28px;

      .form-group {
        margin-bottom: 20px;
        .input-group-text {
          background-color: #fff;
          border-radius: 8px;
        }
        .form-control {
          height: 41px;
          font-size: 14px;
          border-radius: 8px;
        }

        .input-psw {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
        }
      }

      .submit {
        padding: 9px;
        background-color: #0a0a0a;
        border: none;
        border-radius: 9px;
        font-size: 14px;
        &:hover {
          background-color: #676464;
        }
      }
    }
    .forgot-psw {
      font-size: 14px;
      a {
        color: black;
      }
    }
  }
}
@media (min-width: 769px) {
  .logo {
    display: none;
  }
  .login-section-wrapper {
    padding: 0 7vw;
  }
}
@media (max-width: 576px) {
  // .login-wrapper .row {
  //   min-height: 100vh;
  //   align-items: center;
  // }
  .login-section .login-body {
    min-width: 90vw !important;
    padding: 0 10px !important;
  }
  .login-wrapper .img-row {
    display: none !important;
  }
}
</style>
