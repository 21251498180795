<template>
  <div class="orders" v-if="loaded">
    <p class="bal_stat alert alert-info" v-if="myApprove && order.order.balance_status != ''">
      <i class="fas fa-info-circle"></i>
      {{ order.order.balance_status }}
    </p>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0 card-title">
        Purchase <span>{{ order.order.order_no }}</span>
      </h4>

      <!-- <button class="btn btn-info" @click="payNow()">Pay now</button> -->
      <div v-if="myApprove && myApprove.APPROVAL_STATUS == 0" class="order-actions">
        <button class="btn btn-outline-success f-14" data-bs-toggle="modal" data-bs-target="#approveModal"><i class="fas fa-check me-1"></i> Approve</button>
        <button class="btn btn-outline-danger ms-1 f-14" data-bs-toggle="modal" data-bs-target="#rejectModal"><i class="fas fa-times me-1"></i> Decline</button>

        <router-link :to="'/order/edit/' + $route.params.id" class="btn btn-outline-primary f-14 ms-1"> <i class="fa fa-pencil me-2"></i>Edit </router-link>
      </div>

      <div v-if="myEndorse && myEndorse.endorsement_status == 0" class="order-actions">
        <button class="btn btn-outline-success f-14" data-bs-toggle="modal" data-bs-target="#endorseModal"><i class="fas fa-check me-1"></i> Endorse</button>
        <button class="btn btn-outline-danger ms-1 f-14" data-bs-toggle="modal" data-bs-target="#rejectEndorseOrder"><i class="fas fa-times me-1"></i> Decline</button>

        <router-link :to="'/order/edit/' + $route.params.id" class="btn btn-outline-primary f-14 ms-1"> <i class="fa fa-pencil me-2"></i>Edit </router-link>
      </div>
    </div>
    <div class="orders-body mt-2">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Product name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Shipping</th>
                  <th v-if="order.order.merchant == 'Amazon'">Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in order.product" :key="index">
                  <td>
                    <router-link v-if="order.order.merchant" :to="`/${order.order.merchant.toLowerCase()}/product${item.link}`">{{ item.description }}</router-link>
                  </td>
                  <td>{{ currency }}{{ formatPrice(item.price) }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ currency }}{{ formatPrice(item.shipping) }}</td>
                  <td v-if="order.order.merchant == 'Amazon'">{{ currency }}{{ formatPrice(item.tax) }}</td>
                  <td>{{ currency }}{{ formatPrice(item.quantity * item.price + item.shipping + item.tax) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="body-footer mt-3">
            <div class="bf-wrapper row justify-content-between">
              <div class="col-md-4">
                <div class="bf-approval">
                  <h6 class="bf-header">Delivery Address</h6>
                  <p class="deli-add">
                    {{ order.order.address }}, {{ order.order.city }},
                    {{ order.order.state }}
                  </p>
                </div>
                <div class="bf-approval">
                  <h6 class="bf-header">Requestor</h6>
                  <p class="deli-add">
                    {{ order.order.order_by_name }}
                  </p>
                </div>
                <div class="bf-approval">
                  <h6 class="bf-header">Supplier</h6>
                  <p class="deli-add">
                    {{ order.order.merchant }}
                  </p>
                </div>
              </div>
              <div class="bt-calc col-md-4">
                <div class="btc-item">
                  <h6 class="bfc-title">Subtotal:</h6>
                  <span>{{ currency }}{{ formatPrice(order.order.total) }}</span>
                </div>
                <div class="btc-item">
                  <h6 class="bfc-title">Shipping:</h6>
                  <span>{{ currency }}{{ formatPrice(order.order.shipping) }}</span>
                </div>
                <div class="btc-item" v-if="order.order.merchant == 'Amazon'">
                  <h6 class="bfc-title">Taxes:</h6>
                  <span>{{ currency }}{{ formatPrice(order.order.tax) }}</span>
                </div>
                <div class="btc-item">
                  <h6 class="bfc-title">Processing fee:</h6>
                  <span>{{ currency }}{{ formatPrice(Math.ceil(proccessing_fee)) }}</span>
                </div>
                <div class="btc-item btci-total">
                  <h6 class="bfc-title">Total:</h6>
                  <span>{{ currency }}{{ formatPrice(Math.ceil(total)) }}</span>
                </div>
              </div>
            </div>
            <div class="bf-wrapper row mt-3">
              <div class="col-12">
                <h6 class="bf-header">Business Purpose</h6>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="2"
                  placeholder="Summary"
                  v-model="summary"
                  :disabled="order.approvers.length > 0 || $store.state.user.email != order.order.order_by"
                ></textarea>
                <textarea
                  class="form-control mt-2"
                  cols="30"
                  rows="4"
                  placeholder="Details"
                  v-model="detail"
                  :disabled="order.approvers.length > 0 || $store.state.user.email != order.order.order_by"
                ></textarea>
              </div>
            </div>
            <div class="bf-wrapper row mt-4">
              <div class="col-md-7">
                <div class="bf-approval" v-if="$store.state.user.email == order.order.order_by && order.approvers.length == 0">
                  <h6 class="bf-header" style="text-transform: initial">Endorser(s)</h6>
                  <div class="m-select">
                    <div class="ms-app d-flex">
                      <multiselect v-model="endorser" :options="endorsers" :disabled="order.endorsers.length > 0" placeholder="Select endorser"> </multiselect>
                      <button class="btn btn-primary px-3 border-0 ms-1 f-12 ed-btn" @click="appendEndorser()">
                        <!-- <i class="fa fa-plus"></i> -->
                        Add Endorser
                      </button>
                    </div>
                    <ul class="px-1 my-2">
                      <li class="ms-li" v-for="(item, key) in selected" :key="key">
                        <div class="ms-aitem mb-2">
                          <div>
                            <span class="me-2">{{ ++key }}.</span>
                            <span>{{ item }}</span>
                          </div>

                          <i class="fa fa-trash text-danger cursor-pointer" @click="removeEndorser(item)"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bf-approvals" v-else>
                  <h6 class="bf-header">Endorsers</h6>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody v-if="order.endorsers.length > 0">
                        <tr v-for="(item, index) in order.endorsers" :key="index">
                          <td>{{ ++index }}</td>
                          <td>{{ item.first_name }} {{ item.last_name }}</td>
                          <td>{{ item.EXECUTOR_ID }}</td>
                          <td>
                            <span class="badge badge-primary" v-if="item.endorsement_status == 0">pending</span>
                            <span class="badge badge-success" v-if="item.endorsement_status == 1">approved</span>
                            <span class="badge badge-danger" v-if="item.endorsement_status == 2">declined</span>
                          </td>
                        </tr>
                      </tbody>
                      <NoData v-else :data="{ colspan: 3, message: 'No endorser found' }"></NoData>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="bf-approval" v-if="$store.state.user.email == order.order.order_by && order.approvers.length == 0">
                  <h6 class="bf-header" style="text-transform: initial">Approver</h6>
                  <div class="">
                    <div class="">
                      <multiselect v-model="approver" :options="approvers" :disabled="order.approvers.length > 0" placeholder="Select approver" class="w-100"> </multiselect>
                    </div>
                    <ul class="px-1 my-2">
                      <li class="ms-li">
                        <div class="ms-aitem mb-2">
                          <span>{{ approver }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bf-approvals" v-else>
                  <h6 class="bf-header">Approver</h6>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody v-if="order.approvers.length > 0">
                        <tr v-for="(item, index) in order.approvers" :key="index">
                          <td>{{ ++index }}</td>
                          <td>{{ item.first_name }} {{ item.last_name }}</td>
                          <td>{{ item.EXECUTOR_ID }}</td>
                          <td>
                            <span class="badge badge-primary" v-if="item.APPROVAL_STATUS == 0">pending</span>
                            <span class="badge badge-success" v-if="item.APPROVAL_STATUS == 1">approved</span>
                            <span class="badge badge-danger" v-if="item.APPROVAL_STATUS == 2">declined</span>
                          </td>
                        </tr>
                      </tbody>
                      <NoData v-else :data="{ colspan: 3, message: 'No approver found' }"></NoData>
                    </table>
                  </div>
                </div>
                <div class="col-12 text-end mt-4">
                  <button
                    class="btn btn-primary mt-2 f-14 px-5"
                    :disabled="loading"
                    @click="submitForApproval()"
                    v-if="order.approvers.length == 0 && $store.state.user.email == order.order.order_by"
                  >
                    <span v-if="!loading">Submit</span>
                    <span v-else>Loading...</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="activity mt-5">
              <h6 class="f-15">Activity</h6>
              <div v-if="order.order.activities.length > 0">
                <div class="activity-body" v-for="(item, key) in order.order.activities" :key="key">
                  <div class="act-item">
                    <div class="act-icon">{{ item.activity[0] }}</div>
                    <div class="act-content">
                      <p>{{ item.activity }}</p>
                      <span class="text-muted">{{ new Date(item.activity_date).toLocaleString() }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <table class="w-100" v-else>
                <thead>
                  <tr>
                    <td></td>
                  </tr>
                </thead>
                <NoData :data="{ colspan: 1, message: 'No activity' }"></NoData>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="approveModal" tabindex="-1" aria-labelledby="approveModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="approveModalLabel">Approve Order</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="otpSent">
              <h6 class="otp-notify alert alert-info">
                OTP has been sent to your number that ends with ***{{ $store.state.user.user_phone.slice(-4) }}
                and to your email
              </h6>
              <form action="">
                <div class="form-group">
                  <label for="otp" class="f-14">OTP</label>
                  <input type="text" id="otp" class="form-control" v-model="otp" />
                  <span class="resendOtp cursor" @click.prevent="sendOTP()">Resend OTP</span>
                </div>
                <div class="form-group">
                  <label for="notes">Comment</label>
                  <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="3"> </textarea>
                </div>
                <button class="btn btn-success" :disabled="loading" @click.prevent="approveOrder()">
                  <span v-if="!loading">Approve</span>
                  <span v-else>Loading...</span>
                </button>
              </form>
            </div>
            <div class="sendOtp d-flex flex-column align-items-center" v-else>
              <div class="so-i">
                <i class="fas fa-info"></i>
              </div>
              <p class="my-4">To approve this payment request, please generate an OTP</p>
              <button class="btn btn-success f-14" @click.prevent="sendOTP()">
                <span v-if="!loading">Send OTP</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="endorseModal" tabindex="-1" aria-labelledby="endorseModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="endorseModalLabel">Endorse Order</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group">
                <label for="notes">Comment</label>
                <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="5"> </textarea>
              </div>
              <button class="btn btn-success" :disabled="loading" @click="endorseOrder()">
                <span v-if="!loading">Approve</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="rejectModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="rejectModalLabel">Decline Request</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group">
                <label for="notes">Comment</label>
                <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="5"> </textarea>
              </div>
              <button class="btn btn-danger" :disabled="loading" @click="rejectOrder()">
                <span v-if="!loading">Decline</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="rejectEndorseOrder" tabindex="-1" aria-labelledby="rejectEndorseOrderLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="rejectEndorseOrderLabel">Decline Request</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="form-group">
                <label for="notes">Comment</label>
                <textarea class="form-control" id="notes" v-model="notes" cols="30" rows="5"> </textarea>
              </div>
              <button class="btn btn-danger" :disabled="loading" @click.prevent="rejectEndorseOrder()">
                <span v-if="!loading">Decline</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import NoData from '@/components/dashboard/noData.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
  components: { Multiselect, NoData },
  data() {
    return {
      loading: false,
      order: [],
      loaded: false,
      selected: [],
      approvers: [],
      approver: '',
      endorsers: [],
      endorser: '',
      proccessing_fee: 0,
      total: 0,
      subtotal: 0,
      notes: '',
      summary: '',
      detail: '',
      currency: '',
      myApprove: {},
      myEndorse: {},
      otp: '',
      otpSent: false,
    };
  },
  methods: {
    removeEndorser(email) {
      this.selected.splice(
        this.selected.findIndex((item) => item == email),
        1
      );
      this.endorsers.push(email);
      this.$forceUpdate();
    },
    appendEndorser() {
      // console.log(event);
      if (!this.endorser || this.endorser.length < 1) {
        return false;
      }
      this.selected.push(this.endorser);
      this.endorsers.splice(
        this.endorsers.findIndex((item) => item == this.endorser),
        1
      );
      this.endorser = '';
      this.$forceUpdate();
    },
    submitForApproval() {
      // console.log(this.selected);
      if (!this.approver) {
        window.ToasterAlert('error', 'Approver field is required');
        return false;
      }
      var details = {
        order_id: this.$route.params.id,
        endorsers: this.selected,
        approvers: [this.approver],
        summary: this.summary,
        detail: this.detail,
      };
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'orderapproval/create/' + this.$store.state.user.user_id,
          details: details,
        })
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          window.SweetAlert('success', 'Request submitted for approval.');
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch('handleError', err);
        });
    },
    rejectOrder() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/reject/' + this.$store.state.user.user_id,
          details: { order_id: this.order.order.order_id, notes: this.notes },
        })
        .then((resp) => {
          this.loading = false;
          window.$('#rejectModal').modal('hide');
          window.SweetAlert('success', resp.data);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.loading = false;
          window.$('#rejectModal').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    rejectEndorseOrder() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'endorsementqueue/orders/reject/' + this.$store.state.user.user_id,
          details: { order_id: this.order.order.order_id, notes: this.notes },
        })
        .then((resp) => {
          this.loading = false;
          window.$('#rejectEndorseOrder').modal('hide');
          window.SweetAlert('success', resp.data);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.loading = false;
          window.$('#rejectEndorseOrder').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    approveOrder() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/approve/' + this.$store.state.user.user_id,
          details: {
            order_id: this.order.order.order_id,
            notes: this.notes,
            otp: this.otp,
          },
        })
        .then((resp) => {
          this.loading = false;
          window.$('#approveModal').modal('hide');
          window.SweetAlert('success', resp.data);
          window.location.reload();
        })
        .catch((err) => {
          this.loading = false;
          window.$('#approveModal').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    endorseOrder() {
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: 'endorsementqueue/orders/endorse/' + this.$store.state.user.user_id,
          details: {
            order_id: this.order.order.order_id,
            notes: this.notes,
          },
        })
        .then((resp) => {
          this.loading = false;
          window.$('#endorseModal').modal('hide');
          window.SweetAlert('success', resp.data);
          window.location.reload();
        })
        .catch((err) => {
          this.loading = false;
          window.$('#endorseModal').modal('hide');
          this.$store.dispatch('handleError', err);
        });
    },
    sendOTP() {
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'approvalqueue/order/approve-otp/' + this.$store.state.user.user_id,
          details: { order_id: this.order.order.order_id },
        })
        .then(() => {
          this.otpSent = true;
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'OTP sent successfully');
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    getOrder() {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('get', `users/orderproducts/${this.$route.params.id}/${this.$store.state.user.user_id}`).then((resp) => {
        this.$store.commit('setLoader', false);
        console.log(resp);
        this.order = resp.data;
        resp.data.approvers.map((item) => this.selected.push(item.EXECUTOR_ID));
        this.loaded = true;
        this.subtotal = this.order.order.total + this.order.order.shipping + this.order.order.tax;
        this.proccessing_fee = 0.03 * this.subtotal;
        this.total = this.subtotal + this.proccessing_fee;
        this.currency = this.order.order.merchant == 'Amazon' ? '$' : '₦';
        this.myApprove = this.order.approvers.find((item) => item.EXECUTOR_ID == this.$store.state.user.email);
        this.myEndorse = this.order.endorsers.find((item) => item.EXECUTOR_ID == this.$store.state.user.email);
        console.log(this.myApprove);
        this.summary = this.order.order.summary;
        this.detail = this.order.order.detail;
      });
    },
    getEndorsers() {
      this.$store.dispatch('get', 'users/endorsers/read/' + this.$store.state.user.user_id).then((resp) => {
        console.log(resp);
        resp.data.map((item) => this.endorsers.push(item.EXECUTOR_ID));
        console.log(this.endorsers);
      });
    },
    getApprovals() {
      this.$store.dispatch('get', 'users/approvers/read/' + this.$store.state.user.user_id).then((resp) => {
        console.log(resp);
        resp.data.map((item) => this.approvers.push(item.EXECUTOR_ID));
        console.log(this.approvers);
      });
    },
  },
  created() {
    this.getOrder();
    this.getApprovals();
    this.getEndorsers();
  },
};
</script>
<style scoped lang="scss">
.multiselect {
  width: 77%;
}
.ms-aitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ms-li {
  list-style: none;
  font-size: 16px;
}
.bal_stat {
  font-size: 13px;
  padding: 5px;
}
.activity {
  .act-item {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .act-icon {
      width: 33px;
      min-width: 33px;
      height: 33px;
      border-radius: 33px;
      color: #fff;
      background: #068759;
      text-align: center;
      line-height: 33px;
      text-transform: capitalize;
      font-weight: 500;
    }
    .act-content {
      margin-left: 15px;
      font-size: 13px;

      p {
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }
}
.order-actions {
  .btn {
    padding: 5px 10px;
  }
}
table thead tr th {
  color: #5a5f7d;
  background: #f8f9fb;
}
table tbody tr td {
  padding: 16px;
  color: #272b41;
}
.card-title {
  font-size: 18px;
}
textarea.form-control {
  font-size: 14px;
  padding: 1px 5px;
}
.body-footer {
  .bf-approvals {
    .bf-header {
      margin-bottom: 8px;
    }
  }
  .bf-header {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  .bf-wrapper {
    .bf-header,
    .deli-add {
      font-size: 14px;
      text-transform: capitalize;
    }
    .bt-calc {
      .btc-item {
        display: flex;
        justify-content: space-between;
        .bfc-title {
          font-size: 15px;
        }
      }
      .btci-total {
        border-top: 1px solid gray;
        margin-top: 10px;
        padding-top: 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .col-md-4,
  .bf-wrapper .col-md-6 {
    margin-top: 20px;
  }
}
</style>
