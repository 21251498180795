<template>
  <div class="user" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title">Team Member Details</h4>
      <button class="btn btn-primary-outline f-13" data-toggle="modal" data-target="#deleteUser">Archive Member</button>
    </div>
    <div class="user-body mt-2">
      <div class="row">
        <div class="col-md-6">
          <div class="card card-show">
            <div class="card-header">
              <h6 class="card-title">Personal Information</h6>
            </div>
            <div class="card-body">
              <div class="form row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">First Name <span class="required">*</span></label>
                    <input class="form-control" type="text" v-model="user.first_name" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Last Name <span class="required">*</span></label>
                    <input class="form-control" type="text" v-model="user.last_name" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Email <span class="required">*</span></label>
                    <input class="form-control" v-model="user.email" disabled type="text" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Phone <span class="required">*</span></label>
                    <input class="form-control" type="text" v-model="user.phone" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Group</label>
                    <multiselect v-model="group" :options="groups" placeholder="Select group" label="title"></multiselect>
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-purple" @click="updateUser()">
                    <span v-if="!loading">Update</span>
                    <span v-else>Loading...</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card card-show">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h6 class="card-title">Internal Permissions</h6>

              <div class="toggle-all">
                <label class="switch mb-0">
                  <input type="checkbox" @change="enableAll()" />
                  <span class="slider round"></span>
                </label>
                <span class="f-13 ms-2">Enable all</span>
              </div>
            </div>
            <div class="card-body">
              <div class="" v-for="(role, index) in roles" :key="index">
                <div class="py-1 d-flex align-items-center bg-transparent">
                  <div class="toggle">
                    <label class="switch mb-0">
                      <input type="checkbox" @change="addRole($event)" :value="role.id" :checked="checkAll || havePermission(role.id) ? setTrue : !setTrue" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="role_cont">
                    <h6 class="f-14">{{ role.role_name }}</h6>
                    <p class="f-12" v-if="role.id == 4">
                      This access allows a member within your business to make a request for products and services. The end-users of internal business operations purchases.
                    </p>
                    <p class="f-12" v-if="role.id == 84">This allows the member to create payee and request payment.</p>
                    <p class="f-12" v-if="role.id == 54">An endorser can view, edit, decline or endorse purchase requests. An endorser is not a final approver.</p>
                    <p class="f-12" v-if="role.id == 34">
                      This access enables the member to create and manage business profile, team member, groups and integrations with third-party services.
                    </p>
                    <p class="f-12" v-if="role.id == 14">This allows a team member the permission to view, edit, decline or approve purchase requests from within your business.</p>
                    <p class="f-12" v-if="role.id == 24">
                      This allows a member to view purchases and transactions history, add funds to the company wallet and generate expense reports.
                    </p>
                    <p class="f-12" v-if="role.id == 44">This enables unrestricted access to all aspects of the dashboard including creating and managing your business account.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-purple px-4" @click="updateRole()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="deleteUser" tabindex="-1" aria-labelledby="approveUserLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="d-flex justify-content-end p-2">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body pt-3 pb-5">
            <div class="d-flex justify-content-center cancR">
              <!-- <i class="fa fa-info-circle"></i> -->
              <p class="mb-2 mt-3 f-15 text-center">Please confirm you want to archive the user. Note that the team member will lose access to this organization.</p>
              <button class="btn btn-primary mt-3 f-14" @click.prevent="deleteUser()" :disabled="loading">
                <span v-if="!loading">Confirm</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      roles: [],
      user: {},
      loaded: false,
      setTrue: true,
      permissions: [],
      loading: false,
      checkAll: false,
      groups: [],
      group: null,
    };
  },
  methods: {
    havePermission(value) {
      return this.permissions.some((item) => {
        return item == value;
      });
    },
    enableAll() {
      if (this.checkAll) {
        this.checkAll = false;
        this.permissions = this.parsePermissions(this.user.role_id);
      } else {
        this.checkAll = true;
        this.permissions = [];
        this.roles.forEach((item) => this.permissions.push(item.id));
      }
    },
    deleteUser() {
      this.$store.commit('setLoader', true);
      this.loading = true;
      this.$store
        .dispatch('post', {
          endpoint: `users/delete/${this.$store.state.user.id}`,
          details: { user_id: this.user.user_id },
        })
        .then((resp) => {
          console.log(resp);
          // this.$router.push("/users");
          window.location = '/users';
        });
    },
    updateUser() {
      if (this.group) {
        this.user.group_id = this.group.id;
      }
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/userprofile/update/' + this.$store.state.user.id,
          details: this.user,
        })
        .then((resp) => {
          console.log(resp);
          this.$store.commit('setLoader', false);
          window.SweetAlert('success', 'User details updated successfully');
        });
    },
    async getUsers() {
      await this.$store.dispatch('get', 'users/one/' + this.$route.params.id).then((resp) => {
        if (!resp.data) {
          window.SweetAlert('error', 'User not found');
          return false;
        }
        console.log(resp);
        this.user = resp.data;
        this.permissions = this.parsePermissions(this.user.role_id);
        this.loaded = true;
      });
    },
    getRoles() {
      this.$store.dispatch('get', 'users/roles').then((resp) => {
        this.roles = resp.data;
      });
    },
    addRole(event) {
      var check = this.permissions.find((item) => {
        return item == event.target.value;
      });
      if (check) {
        this.permissions.splice(
          this.permissions.findIndex((item) => item == event.target.value),
          1
        );
      } else {
        this.permissions.push(event.target.value);
      }
    },
    updateRole() {
      this.$store.commit('setLoader', true);
      const permit = this.permissions.join(',');
      console.log(permit);
      this.$store
        .dispatch('post', {
          endpoint: 'users/approveusers/' + this.$store.state.user.id,
          details: {
            user_id: this.user.user_id,
            role_id: permit,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'User role updated successfully');
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    getGroups() {
      this.$store.dispatch('get', `company/groups/${this.$store.state.user.id}`).then((resp) => {
        this.groups = resp.data;
        this.group = this.groups.find((item) => item.id == this.user.group_id);
        console.log(this.group);
      });
    },
  },
  async created() {
    this.getRoles();
    await this.getUsers();
    this.getGroups();
  },
};
</script>

<style>
.user-body .multiselect__single {
  color: #9f9f9f;
}
</style>
<style scoped lang="scss">
.card-show {
  margin-bottom: 10px;
  padding: 0;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  .card-header {
    border-bottom: none;
    padding: 15px;
    background-color: #fafaff;
    border-radius: 8px;
    border-bottom: 1px solid #dfdfdf;
    .card-title {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  label {
    font-size: 14px;
    color: #6a6a6a;
    margin-bottom: 2px;
  }
  .form-control {
    font-size: 13px;
    color: #9f9f9f;
  }
  .role_cont {
    margin-left: 15px;
    p {
      color: gray;
      line-height: 1.4;
      margin-bottom: 9px;
    }
    h6 {
      margin-bottom: 2px;
    }
  }
}
input:checked + .slider {
  background-color: #022438;
}
input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
  left: 1px;
  bottom: 3.5px;
  background: #fff;
}
.slider {
  background-color: #fff;
  border: 1px solid #dfdfdf;
}
.slider:before {
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: #022438;
}
.switch {
  width: 33px;
  height: 20px;
}

.btn-view {
  color: #0d6efd;
  background-color: #0d6efd30;
  border: none;
}
@media (max-width: 576px) {
  .card-body {
    padding: 1rem 15px;
  }
}
</style>
